import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const Modal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
`;

const ModalContent = styled(motion.div)`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #C19B76;
    border-radius: 4px;
  }
`;

const Title = styled.h2`
  color: #1a1a1a;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h3`
  color: #1a1a1a;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: 500;
`;

const Description = styled.p`
  color: #666;
  font-size: 0.95rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const PreferenceItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: #f8f8f8;
  border-radius: 8px;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const PreferenceInfo = styled.div`
  flex: 1;
`;

const PreferenceName = styled.h4`
  color: #1a1a1a;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const PreferenceDescription = styled.p`
  color: #666;
  font-size: 0.9rem;
  line-height: 1.5;
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  margin-left: 1rem;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: #C19B76;
  }

  &:checked + span:before {
    transform: translateX(24px);
  }

  &:disabled + span {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
`;

const Button = styled.button<{ primary?: boolean }>`
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;

  ${({ primary }) => primary ? `
    background: #C19B76;
    color: white;

    &:hover {
      background: #A88B69;
    }
  ` : `
    background: #f1f1f1;
    color: #666;

    &:hover {
      background: #e1e1e1;
    }
  `}
`;

interface CookiePreference {
  id: string;
  name: string;
  description: string;
  required?: boolean;
  enabled: boolean;
}

interface CookiePreferencesProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (preferences: { [key: string]: boolean }) => void;
}

const CookiePreferences: React.FC<CookiePreferencesProps> = ({ isOpen, onClose, onSave }) => {
  const [preferences, setPreferences] = useState<CookiePreference[]>([
    {
      id: 'necessary',
      name: 'Cookie Necessari',
      description: 'Questi cookie sono essenziali per il funzionamento del sito web e non possono essere disattivati.',
      required: true,
      enabled: true
    },
    {
      id: 'functional',
      name: 'Cookie Funzionali',
      description: 'Questi cookie permettono al sito web di ricordare le scelte che hai fatto in passato.',
      enabled: false
    },
    {
      id: 'analytics',
      name: 'Cookie Analitici',
      description: 'Questi cookie ci aiutano a capire come i visitatori interagiscono con il sito web.',
      enabled: false
    },
    {
      id: 'marketing',
      name: 'Cookie Marketing',
      description: 'Questi cookie vengono utilizzati per tracciare i visitatori sui siti web.',
      enabled: false
    }
  ]);

  useEffect(() => {
    // Carica le preferenze salvate
    const savedPreferences = localStorage.getItem('cookie-preferences');
    if (savedPreferences) {
      const parsed = JSON.parse(savedPreferences);
      setPreferences(prefs => prefs.map(pref => ({
        ...pref,
        enabled: pref.required || parsed[pref.id] || false
      })));
    }
  }, []);

  const handleToggle = (id: string) => {
    setPreferences(prefs => prefs.map(pref => 
      pref.id === id ? { ...pref, enabled: !pref.enabled } : pref
    ));
  };

  const handleSave = () => {
    const preferencesObject = preferences.reduce((acc, pref) => ({
      ...acc,
      [pref.id]: pref.enabled
    }), {});
    
    localStorage.setItem('cookie-preferences', JSON.stringify(preferencesObject));
    onSave(preferencesObject);
    onClose();
  };

  const handleAcceptAll = () => {
    setPreferences(prefs => prefs.map(pref => ({ ...pref, enabled: true })));
    const allEnabled = preferences.reduce((acc, pref) => ({
      ...acc,
      [pref.id]: true
    }), {});
    
    localStorage.setItem('cookie-preferences', JSON.stringify(allEnabled));
    onSave(allEnabled);
    onClose();
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <Modal
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
        >
          <ModalContent
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            onClick={e => e.stopPropagation()}
          >
            <Title>Preferenze Cookie</Title>
            
            <Section>
              <SectionTitle>Gestisci le tue preferenze</SectionTitle>
              <Description>
                Utilizziamo i cookie per migliorare la tua esperienza di navigazione, fornire contenuti personalizzati
                e analizzare il traffico del sito. Puoi scegliere quali cookie accettare.
              </Description>
              
              {preferences.map(preference => (
                <PreferenceItem key={preference.id}>
                  <PreferenceInfo>
                    <PreferenceName>{preference.name}</PreferenceName>
                    <PreferenceDescription>{preference.description}</PreferenceDescription>
                  </PreferenceInfo>
                  <Switch>
                    <SwitchInput
                      type="checkbox"
                      checked={preference.enabled}
                      onChange={() => !preference.required && handleToggle(preference.id)}
                      disabled={preference.required}
                    />
                    <SwitchSlider />
                  </Switch>
                </PreferenceItem>
              ))}
            </Section>

            <ButtonGroup>
              <Button onClick={onClose}>Chiudi</Button>
              <Button primary onClick={handleSave}>Salva Preferenze</Button>
              <Button primary onClick={handleAcceptAll}>Accetta Tutti</Button>
            </ButtonGroup>
          </ModalContent>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default CookiePreferences;
