import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaArrowLeft } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';

const destinations = [
  {
    id: 1,
    name: 'Watamu',
    shortDescription: 'Paradiso costiero con spiagge di sabbia bianca e acque cristalline.',
    description: 'Watamu, con le sue acque turchesi e le spiagge di sabbia bianca, è un autentico paradiso sulla costa kenyana. Sede delle nostre ville private più esclusive, offre il massimo del comfort e della privacy per una vacanza da sogno. Famosa per il suo Parco Marino, è il luogo perfetto per snorkeling, immersioni e passeggiate tra mangrovie. Qui natura incontaminata, relax e cultura locale si incontrano per regalarti un\'esperienza unica.',
    category: 'escursioni',
    image: '/images/destinations/watamu.jpg'
  },
  {
    id: 2,
    name: 'Tamani Jua',
    shortDescription: 'Esperienza unica di snorkeling nelle acque cristalline con pranzo a bordo.',
    description: 'Tamani Jua è l’esperienza esclusiva in barca che ti porta nel cuore del Parco Marino di Watamu. A bordo di un elegante dhow, trascorrerai una giornata tra acque cristalline, snorkeling tra pesci colorati, tartarughe marine e, con un po\’ di fortuna, nuoterai accanto ai delfini. Il pranzo a bordo include pesce fresco preparato al momento, seguito da un aperitivo al tramonto per concludere la giornata in un\'atmosfera magica. L\'esperienza include skipper, equipaggio e ticket d\'ingresso al parco marino. Una giornata che unisce avventura, relax e meraviglie naturali.',
    category: 'escursioni',
    image: '/images/destinations/tamani-jua.jpg'
  },
  {
    id: 3,
    name: 'Gede',
    shortDescription: 'Antica città swahili del XIII secolo, ora sito archeologico UNESCO.',
    description: 'Gede è un\’antica città swahili immersa nella foresta, un luogo ricco di mistero e fascino storico. Le sue rovine, risalenti al XIII secolo, raccontano storie di una civiltà prospera e avanzata. Passeggiando tra i resti di palazzi, moschee e case in pietra, potrai scoprire l\’eredità culturale del Kenya. Circondata da una foresta rigogliosa, Gede offre anche un\’esperienza naturalistica unica, con possibilità di avvistare scimmie, farfalle e altre specie locali. Perfetta per chi ama la storia, l\’archeologia e la connessione con la natura.',
    category: 'cultura',
    image: '/images/destinations/gede.jpg'
  },
  {
    id: 4,
    name: 'Lamu',
    shortDescription: 'Isola storica UNESCO con la più antica città swahili ancora abitata.',
    description: 'Lamu, patrimonio dell\’UNESCO, è un\’isola incantevole che conserva intatta la sua atmosfera swahili autentica. Tra stretti vicoli senza tempo, case in pietra corallina e mercati locali, ogni angolo racconta una storia di tradizioni e cultura. Qui il ritmo della vita rallenta: non ci sono auto, solo asini e dhow che solcano il mare. Famosa per le sue spiagge incontaminate, l\’artigianato locale e le esperienze culturali, Lamu è la destinazione ideale per chi cerca autenticità, relax e un viaggio nel passato.',
    category: 'cultura',
    image: '/images/destinations/lamu.jpg'
  },
  {
    id: 5,
    name: 'Marafa - Nyari Sunset',
    shortDescription: 'Spettacolare canyon di arenaria multicolore noto come "Hell\'s Kitchen".',
    description: 'Conosciuta localmente come Nyari, è un luogo unico dove la natura ha scolpito spettacolari canyon dai colori vibranti. L\'escursione ti conduce attraverso questi paesaggi mozzafiato, rivelando la bellezza e il mistero di questa meraviglia geologica. La giornata si conclude con un picnic esclusivo al tramonto: seduti su cuscini morbidi e accoglienti, sorseggiando un aperitivo, potrai ammirare il sole che si tuffa dietro i canyon, regalando un momento di pace e magia irripetibile. Un\’esperienza autentica che unisce avventura e relax.',
    category: 'natura',
    image: '/images/destinations/marafa.jpg'
  },
  {
    id: 6,
    name: 'Centro Culturale',
    shortDescription: 'Immersione nella cultura locale attraverso danze tradizionali e artigianato.',
    description: 'Il Centro Culturale è un\’immersione totale nella cultura locale, un\’esperienza esclusiva di NelloSafari che ti porta a scoprire le tradizioni delle etnie keniote, grazie al nostro legame diretto con la tribù Giriama. Partecipa a danze tradizionali, indossa abiti cerimoniali, esplora l\’artigianato locale e assapora la gastronomia autentica. Potrai anche prendere parte a workshop interattivi per imparare i segreti di questa affascinante cultura. È un\’opportunità unica per connettersi profondamente con lo spirito tribale della costa del Kenya, vivendo momenti autentici e indimenticabili.',
    category: 'cultura',
    image: '/images/destinations/cultural-center.jpg'
  },
  {
    id: 7,
    name: 'Arabuko Sokoke Forest',
    shortDescription: 'La più grande foresta costiera dell\'Africa orientale, habitat di specie rare.',
    description: 'Arabuko Sokoke è la più grande foresta costiera dell\’Africa orientale, un luogo incantato dove natura e biodiversità si incontrano. Questo ecosistema unico ospita specie rare di flora e fauna, tra cui uccelli endemici, gufi, farfalle colorate, elefanti delle foreste e scimmie Sykes. Esplorare Arabuko Sokoke significa immergersi in un ambiente rigoglioso e magico, ideale per chi ama il birdwatching, il trekking e l\’avventura nella natura incontaminata. Perfetta per vivere un\’esperienza autentica e sostenibile nel cuore del Kenya.',
    category: 'natura',
    image: '/images/destinations/arabuko-sokoke.jpg'
  },
  {
    id: 8,
    name: 'Tsavo East',
    shortDescription: 'Uno dei parchi nazionali più grandi del Kenya, famoso per i suoi elefanti rossi.',
    description: 'Tsavo East è uno dei parchi nazionali più grandi e iconici del Kenya, famoso per le sue pianure infinite e la spettacolare fauna selvatica. Qui puoi vivere l\’emozione di avvistare i "big five" e osservare gli elefanti dalla caratteristica polvere rossa, i leoni e le giraffe nel loro habitat naturale. Il parco offre anche meraviglie paesaggistiche, come il fiume Galana e le Lugard Falls. Tsavo East è la destinazione perfetta per un safari indimenticabile, dove la natura selvaggia del Kenya si rivela in tutta la sua maestosità.',
    category: 'safari',
    image: '/images/destinations/tsavo-east.jpg'
  },
  {
    id: 9,
    name: 'Tsavo West',
    shortDescription: 'Parco caratterizzato da paesaggi vulcanici e sorgenti Mzima.',
    description: 'Tsavo West è una meraviglia naturale che combina safari mozzafiato con paesaggi spettacolari. Questo parco è famoso per i suoi contrasti: dalle colline vulcaniche ai laghi scintillanti, fino alle sorgenti di Mzima, dove potrai osservare ippopotami e coccodrilli in acque cristalline. Ricco di fauna selvatica, tra cui elefanti, leoni e rinoceronti, Tsavo West è una destinazione ideale per chi cerca avventura e bellezza naturale. Perfetto per un safari che unisce emozione, natura e panorami indimenticabili.',
    category: 'safari',
    image: '/images/destinations/tsavo-west.jpg'
  },
  {
    id: 10,
    name: 'Amboseli',
    shortDescription: 'Parco nazionale con vista spettacolare sul Kilimanjaro e grandi mandrie di elefanti.',
    description: 'Amboseli è il parco dei contrasti, dove la fauna selvatica incontra la maestosità del Monte Kilimanjaro, che domina l\'orizzonte con la sua cima innevata. Conosciuto come la "terra dei giganti", è famoso per le grandi mandrie di elefanti che popolano le sue pianure. Qui potrai avvistare leoni, ghepardi, zebre e una vasta varietà di uccelli, immerso in uno scenario che sembra uscito da un sogno. Amboseli offre un\’esperienza di safari unica, unendo paesaggi straordinari e l\’emozione della natura incontaminata.',
    category: 'safari',
    image: '/images/destinations/amboseli.jpg'
  },
  {
    id: 11,
    name: 'Kilifi',
    shortDescription: 'Pittoresca città costiera con splendida baia e creek naturale.',
    description: 'Kilifi è un gioiello nascosto sulla costa kenyana, conosciuto per le sue spiagge incontaminate, le acque cristalline e l\’atmosfera rilassata. Questo villaggio costiero offre un perfetto mix di natura e cultura, con opportunità per esplorare le mangrovie, visitare le rovine storiche di Mnarani e immergersi nelle tradizioni locali. Ideale per chi cerca relax, avventura e autenticità, Kilifi è una destinazione che cattura il cuore di chi la visita.',
    category: 'escursioni',
    image: '/images/destinations/kilifi.jpg'
  },
  {
    id: 12,
    name: 'Lago Nakuru',
    shortDescription: 'Famoso per i suoi fenicotteri rosa e i rinoceronti.',
    description: 'Il Lago Nakuru, situato nel cuore della Rift Valley, è un santuario naturale famoso per i suoi fenicotteri rosa che creano un paesaggio mozzafiato. Circondato da foreste e colline, questo parco nazionale ospita una ricca biodiversità, tra cui rinoceronti bianchi e neri, leoni, bufali e diverse specie di uccelli. Ideale per gli amanti della natura e della fotografia, il Lago Nakuru offre un\’esperienza di safari unica in uno scenario spettacolare.',
    category: 'natura',
    image: '/images/destinations/lake-nakuru.jpg'
  },
  {
    id: 13,
    name: 'Nairobi',
    shortDescription: 'Capitale vibrante del Kenya che combina modernità e tradizione.',
    description: 'Nairobi, la vibrante capitale del Kenya, è il perfetto punto di incontro tra modernità e natura selvaggia. Conosciuta come la "Città Verde sotto il Sole", offre esperienze uniche come il Nairobi National Park, dove puoi avvistare leoni e giraffe con lo skyline cittadino sullo sfondo. La città è anche un centro culturale vivace, con musei, mercati artigianali e ristoranti che celebrano la cucina locale. Nairobi è la destinazione ideale per iniziare o concludere il tuo viaggio, immergendoti nella cultura kenyana e nel fascino urbano.',
    category: 'città',
    image: '/images/destinations/nairobi.jpg'
  },
  {
    id: 14,
    name: 'Nairobi National Park',
    shortDescription: 'Unico parco nazionale al mondo situato in una capitale.',
    description: 'Il Nairobi National Park è una meraviglia unica al mondo: un\'area protetta dove la natura selvaggia si incontra con lo skyline di una metropoli. A pochi chilometri dal centro città, questo parco è la casa di leoni, rinoceronti, zebre, giraffe e oltre 400 specie di uccelli. Perfetto per un safari vicino alla capitale, offre un\’esperienza indimenticabile per chi desidera avvicinarsi alla fauna africana senza allontanarsi troppo. Ideale per un\’avventura emozionante in una cornice davvero unica.',
    category: 'safari',
    image: '/images/destinations/nairobi-national-park.jpg'
  },
  {
    id: 15,
    name: 'Masai Mara',
    shortDescription: 'Riserva nazionale rinomata per la Grande Migrazione e i leoni.',
    description: 'Il Masai Mara è il cuore pulsante del safari africano, una riserva iconica dove natura e cultura si incontrano. Famoso per la Grande Migrazione, potrai assistere al maestoso passaggio di migliaia di gnu e zebre tra Kenya e Tanzania, uno degli spettacoli naturali più emozionanti al mondo. Per un\’esperienza unica, vivi un safari in mongolfiera: sorvola le pianure all\’alba e ammira dall\’alto la straordinaria fauna selvatica. Qui, tra i "big five" e la possibilità di incontrare la tribù Masai, il Masai Mara ti regalerà un\’avventura che non dimenticherai mai.',
    category: 'safari',
    image: '/images/destinations/masai-mara.jpg'
  },
  {
    id: 16,
    name: 'Mombasa',
    shortDescription: 'Principale città costiera del Kenya con ricco patrimonio storico.',
    description: 'Mombasa è il cuore pulsante della costa kenyana, un mix perfetto di storia, cultura e spiagge da sogno. Con il suo fascino swahili, questa città portuale offre attrazioni iconiche come il Fort Jesus, patrimonio dell\’UNESCO, e i vivaci mercati locali. Le spiagge di sabbia bianca e le acque cristalline rendono Mombasa ideale per il relax, mentre le sue tradizioni culinarie uniche invitano a un viaggio nei sapori locali. È la destinazione perfetta per chi desidera unire cultura, storia e il meglio della costa africana.',
    category: 'città',
    image: '/images/destinations/mombasa.jpg'
  },
  {
    id: 17,
    name: 'Malindi',
    shortDescription: 'Città costiera famosa per le sue spiagge e il Parco Marino.',
    description: 'Malindi è una perla della costa kenyana, dove storia, cultura e natura si incontrano in un mix unico. Famosa per le sue spiagge dorate e le acque cristalline, è anche un centro culturale vibrante. Visita le Croci di Vasco da Gama e il Parco Marino di Malindi, esplora il vivace mercato locale, dove colori e profumi ti avvolgono, e scopri l\’arte dell\’intaglio tradizionale nella storica fabbrica del legno. Malindi offre un\’esperienza autentica, perfetta per chi cerca avventura, relax e connessione con la cultura locale.',
    category: 'città',
    image: '/images/destinations/malindi.jpg'
  }
];

const Section = styled.section`
  padding: 5rem 2rem;
  background: var(--background-color);
`;

const Title = styled.h2`
  text-align: center;
  color: var(--text-color);
  font-size: 2.5rem;
  margin-bottom: 3rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: var(--primary-color);
  }
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
`;

const FilterButton = styled.button<{ active: boolean }>`
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 25px;
  background: ${props => props.active ? '#C19B76' : 'transparent'};
  color: ${props => props.active ? 'white' : 'var(--text-color)'};
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid #C19B76;
  font-weight: 500;

  &:hover {
    background: #C19B76;
    color: white;
    transform: translateY(-2px);
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 3rem;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const CardContainer = styled.div`
  perspective: 2000px;
  height: 600px;
  width: 100%;
  transform-style: preserve-3d;
  margin: 0 auto;
  max-width: 450px;

  @media (max-width: 768px) {
    height: 550px;
  }
`;

const Card = styled(motion.div)<{ $isFlipped?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  transform: ${props => props.$isFlipped ? 'rotateY(180deg)' : 'rotateY(0)'};
  cursor: pointer;
  transform-origin: center;
  display: flex;
  flex-direction: column;
`;

const CardSide = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.07),
    inset 0 0 0 1px rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.7);
  transform-style: preserve-3d;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:hover {
    background: rgba(255, 255, 255, 0.92);
    box-shadow: 
      0 20px 40px rgba(0, 0, 0, 0.12),
      0 5px 15px rgba(0, 0, 0, 0.08),
      inset 0 0 0 1px rgba(255, 255, 255, 1);
    transform: translateY(-10px);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(
      105deg,
      transparent 35%,
      rgba(255, 255, 255, 0.18) 45%,
      rgba(255, 255, 255, 0.25) 50%,
      rgba(255, 255, 255, 0.18) 55%,
      transparent 65%
    );
    transform: translateX(-100%);
    transition: transform 1.2s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 2;
  }

  &:hover::before {
    transform: translateX(100%);
  }
`;

const CardFront = styled(CardSide)`
  transform: rotateY(0);
`;

const CardBack = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.97),
    rgba(255, 255, 255, 0.95)
  );
  border-radius: 25px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #C19B76;
    border-radius: 3px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 50%;
  position: relative;
  overflow: hidden;
  border-radius: 25px 25px 0 0;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 75%;
    background: linear-gradient(to top, 
      rgba(0, 0, 0, 0.85) 0%,
      rgba(0, 0, 0, 0.6) 35%,
      rgba(0, 0, 0, 0.2) 60%,
      transparent 100%
    );
    pointer-events: none;
    transition: all 0.5s ease;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1.2s cubic-bezier(0.23, 1, 0.32, 1);
    transform-origin: center center;
    filter: saturate(1.1) contrast(1.1);
  }

  ${CardFront}:hover & img {
    transform: scale(1.12) translateY(-2%);
  }

  ${CardFront}:hover &::after {
    height: 85%;
    background: linear-gradient(to top, 
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.7) 35%,
      rgba(0, 0, 0, 0.3) 60%,
      transparent 100%
    );
  }
`;

const CardContent = styled.div`
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.3rem;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.97),
    rgba(255, 255, 255, 0.95)
  );
  position: relative;
  z-index: 1;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
`;

const DestinationTitle = styled.h3`
  font-size: 1.8rem;
  color: #1a1a1a;
  margin: 0;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-bottom: 0.5rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background: #C19B76;
    transition: width 0.3s ease;
  }

  ${CardFront}:hover &::after {
    width: 100px;
  }
`;

const Description = styled.p`
  color: #333;
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 0;
  font-weight: 400;
`;

const BackButton = styled.button`
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  svg {
    color: #1a1a1a;
    font-size: 1.2rem;
  }
`;

const DetailedDescription = styled.div`
  color: #333;
  font-size: 1.1rem;
  line-height: 1.7;
  padding: 2rem;
  
  h4 {
    font-size: 1.8rem;
    color: #1a1a1a;
    margin: 0;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    position: relative;
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 60px;
      height: 3px;
      background: #C19B76;
      transition: width 0.3s ease;
    }

    ${CardBack}:hover &::after {
      width: 100px;
    }
  }

  p {
    margin-bottom: 1.5rem;
    color: #333;
    font-size: 1.1rem;
    line-height: 1.7;
    font-weight: 400;
  }
`;

const MeteSection: React.FC = () => {
  const [activeFilter, setActiveFilter] = useState<string>('tutti');
  const [flippedCard, setFlippedCard] = useState<number | null>(null);
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  const filteredDestinations = activeFilter === 'tutti'
    ? destinations
    : destinations.filter(dest => dest.category === activeFilter);

  const handleCardClick = (id: number) => {
    setFlippedCard(flippedCard === id ? null : id);
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.5,
        ease: [0.4, 0, 0.2, 1]
      }
    })
  };

  return (
    <Section ref={ref}>
      <Title>Le Nostre Mete</Title>
      <FilterContainer>
        <FilterButton
          active={activeFilter === 'tutti'}
          onClick={() => setActiveFilter('tutti')}
        >
          Tutti
        </FilterButton>
        <FilterButton
          active={activeFilter === 'safari'}
          onClick={() => setActiveFilter('safari')}
        >
          Safari
        </FilterButton>
        <FilterButton
          active={activeFilter === 'escursioni'}
          onClick={() => setActiveFilter('escursioni')}
        >
          Escursioni
        </FilterButton>
        <FilterButton
          active={activeFilter === 'cultura'}
          onClick={() => setActiveFilter('cultura')}
        >
          Cultura
        </FilterButton>
        <FilterButton
          active={activeFilter === 'natura'}
          onClick={() => setActiveFilter('natura')}
        >
          Natura
        </FilterButton>
        <FilterButton
          active={activeFilter === 'città'}
          onClick={() => setActiveFilter('città')}
        >
          Città
        </FilterButton>
      </FilterContainer>
      <Grid>
        <AnimatePresence>
          {filteredDestinations.map((destination, index) => (
            <motion.div
              key={destination.id}
              variants={cardVariants}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              custom={index}
              layout
            >
              <CardContainer>
                <Card
                  $isFlipped={flippedCard === destination.id}
                  onClick={() => handleCardClick(destination.id)}
                >
                  <CardFront>
                    <ImageContainer>
                      <img src={destination.image} alt={destination.name} />
                    </ImageContainer>
                    <CardContent>
                      <DestinationTitle>{destination.name}</DestinationTitle>
                      <Description>{destination.shortDescription}</Description>
                    </CardContent>
                  </CardFront>
                  <CardBack>
                    <BackButton onClick={(e) => {
                      e.stopPropagation();
                      setFlippedCard(null);
                    }}>
                      <FaArrowLeft />
                    </BackButton>
                    <DetailedDescription>
                      <h4>{destination.name}</h4>
                      <p>{destination.description}</p>
                    </DetailedDescription>
                  </CardBack>
                </Card>
              </CardContainer>
            </motion.div>
          ))}
        </AnimatePresence>
      </Grid>
    </Section>
  );
};

export default MeteSection;
