import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Section = styled.section`
  padding: 120px 2rem 5rem;
  background: var(--background-color);
  min-height: 100vh;
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  color: #333;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #1a1a1a;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 600;
  position: relative;
  padding-bottom: 1rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: #C19B76;
  }
`;

const Content = styled.div`
  line-height: 1.8;
  font-size: 1.1rem;

  h2 {
    color: #1a1a1a;
    font-size: 1.8rem;
    margin: 2.5rem 0 1rem;
    font-weight: 600;
  }

  h3 {
    color: #1a1a1a;
    font-size: 1.4rem;
    margin: 2rem 0 1rem;
    font-weight: 500;
  }

  p {
    margin-bottom: 1.5rem;
  }

  ul {
    margin-bottom: 1.5rem;
    padding-left: 2rem;

    li {
      margin-bottom: 0.5rem;
    }
  }

  a {
    color: #C19B76;
    text-decoration: underline;
    
    &:hover {
      color: #A88B69;
    }
  }
`;

const LastUpdate = styled.p`
  font-style: italic;
  color: #666;
  text-align: center;
  margin-top: 3rem;
`;

const PrivacyPolicy: React.FC = () => {
  return (
    <Section>
      <Container>
        <Title>Privacy Policy</Title>
        <Content>
          <h2>Informativa sulla Privacy</h2>
          <p>
            La presente informativa descrive le modalità di gestione del sito web www.nellosafari.com e www.nellosafari.it, in riferimento al trattamento dei dati personali degli utenti che lo consultano.
          </p>

          <h2>Titolare del Trattamento</h2>
          <p>
            Il titolare del trattamento dei dati è NelloSafari, con sede in Malindi P.O BOX 381 - Kenya, che può essere contattato all'indirizzo email: info@nellosafari.com
          </p>

          <h2>Tipologie di Dati Raccolti</h2>
          <h3>Dati di Navigazione</h3>
          <p>
            I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet.
          </p>

          <h3>Dati Forniti Volontariamente</h3>
          <p>
            L'invio facoltativo, esplicito e volontario di messaggi agli indirizzi di contatto, nonché la compilazione e l'inoltro dei moduli presenti sul sito, comportano l'acquisizione dei dati di contatto del mittente, necessari a rispondere, nonché di tutti i dati personali inclusi nelle comunicazioni.
          </p>

          <h2>Finalità del Trattamento</h2>
          <ul>
            <li>Permettere la navigazione del sito</li>
            <li>Rispondere alle richieste degli utenti</li>
            <li>Inviare preventivi richiesti</li>
            <li>Gestire le prenotazioni</li>
            <li>Adempiere agli obblighi di legge</li>
          </ul>

          <h2>Cookie</h2>
          <p>
            Il nostro sito utilizza cookie tecnici necessari al funzionamento del sito e cookie analitici per migliorare l'esperienza di navigazione. L'utente può scegliere se accettare o meno i cookie attraverso il banner presente sul sito.
          </p>

          <h2>Diritti degli Interessati</h2>
          <p>
            Gli interessati hanno il diritto di ottenere, nei casi previsti, l'accesso ai propri dati personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento che li riguarda o di opporsi al trattamento (artt. 15 e ss. del Regolamento).
          </p>

          <h2>Modifiche alla Privacy Policy</h2>
          <p>
            La presente privacy policy può essere soggetta a modifiche nel tempo, anche connesse all'eventuale entrata in vigore di nuove normative di settore, all'aggiornamento o erogazione di nuovi servizi ovvero ad intervenute innovazioni tecnologiche.
          </p>
        </Content>
        <LastUpdate>Ultimo aggiornamento: {new Date().toLocaleDateString('it-IT')}</LastUpdate>
      </Container>
    </Section>
  );
};

export default PrivacyPolicy;
