export const initializeContentProtection = () => {
  // Prevent right click
  document.addEventListener('contextmenu', (e) => {
    if (e.target instanceof HTMLImageElement || e.target instanceof HTMLVideoElement) {
      e.preventDefault();
    }
  });

  // Prevent keyboard shortcuts
  document.addEventListener('keydown', (e) => {
    // Prevent: Ctrl + S, Ctrl + U, Ctrl + Shift + I, F12
    if (
      (e.ctrlKey && e.key === 's') || 
      (e.ctrlKey && e.key === 'u') ||
      (e.ctrlKey && e.shiftKey && e.key === 'i') ||
      e.key === 'F12'
    ) {
      e.preventDefault();
    }
  });

  // Prevent drag and drop
  document.addEventListener('dragstart', (e) => {
    if (e.target instanceof HTMLImageElement) {
      e.preventDefault();
    }
  });
};

// Custom error message for console
export const addConsoleWarning = () => {
  console.log(
    '%c⚠️ ATTENZIONE: Tutti i contenuti sono protetti da copyright',
    'color: red; font-size: 24px; font-weight: bold;'
  );
  console.log(
    '%c© NelloSafari - Tutti i diritti riservati',
    'font-size: 16px;'
  );
};
