import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ProtectedImage from '../common/ProtectedImage';
import { useInView } from 'react-intersection-observer';

const GalleryContainer = styled(motion.div)`
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
`;

const GalleryWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const MainCarousel = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 70vh;
  min-height: 500px;
  max-height: 800px;
  margin: 2rem 0;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  background: #f8f9fa;

  @media (max-width: 768px) {
    height: 50vh;
    min-height: 400px;
  }
`;

const MainImage = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      transparent 60%,
      rgba(0, 0, 0, 0.2) 100%
    );
    pointer-events: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    will-change: transform;
    transform: translateZ(0);
    backface-visibility: hidden;
  }
`;

const CarouselButton = styled(motion.button)<{ direction: 'left' | 'right' }>`
  position: absolute;
  top: 50%;
  ${props => props.direction === 'left' ? 'left: 20px;' : 'right: 20px;'}
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.9);
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background: white;
    transform: translateY(-50%) scale(1.1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  svg {
    font-size: 20px;
    color: #333;
  }
`;

const CardGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
  padding: 0.5rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }
`;

const Card = styled(motion.div)`
  position: relative;
  width: 100%;
  padding-top: 75%; /* 4:3 aspect ratio */
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  background: #f8f9fa;
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      transparent 50%,
      rgba(0, 0, 0, 0.2) 100%
    );
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }

  &:hover::after {
    opacity: 1;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    will-change: transform;
    transform: translateZ(0);
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: translateZ(0) scale(1.05);
  }
`;

const SectionTitle = styled(motion.h2)`
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 40px;
  font-weight: 600;
`;

const galleryImages = [
  '/images/gallery/gallery1.jpg',
  '/images/gallery/gallery2.jpg',
  '/images/gallery/gallery3.jpg',
  '/images/gallery/gallery4.jpg',
  '/images/gallery/gallery5.jpg',
  '/images/gallery/gallery6.jpg',
  '/images/gallery/gallery7.jpg',
  '/images/gallery/gallery8.jpg',
  '/images/gallery/gallery9.jpg',
  '/images/gallery/gallery10.jpg',
  '/images/gallery/gallery11.jpg',
  '/images/gallery/gallery12.jpg',
  '/images/gallery/gallery13.jpg'
];

const GallerySection: React.FC = () => {
  const [mainIndex, setMainIndex] = useState(0);
  const [cardIndices, setCardIndices] = useState([1, 2, 3].map(i => i % galleryImages.length));
  const [direction, setDirection] = useState<'left' | 'right'>('right');
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
    rootMargin: '50px'
  });

  const nextMainImage = () => {
    setDirection('right');
    setMainIndex((prev) => (prev + 1) % galleryImages.length);
  };

  const prevMainImage = () => {
    setDirection('left');
    setMainIndex((prev) => (prev - 1 + galleryImages.length) % galleryImages.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextMainImage();
      // Rotate card images with a slight delay for visual interest
      setTimeout(() => {
        setCardIndices(prev => prev.map(index => (index + 1) % galleryImages.length));
      }, 250);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const variants = {
    enter: (direction: 'left' | 'right') => ({
      x: direction === 'right' ? 1000 : -1000,
      opacity: 0,
      scale: 0.9
    }),
    center: {
      x: 0,
      opacity: 1,
      scale: 1
    },
    exit: (direction: 'left' | 'right') => ({
      x: direction === 'right' ? -1000 : 1000,
      opacity: 0,
      scale: 0.9
    })
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.3, ease: "easeOut" }
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
        staggerChildren: 0.1
      }
    }
  };

  return (
    <GalleryContainer
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={containerVariants}
    >
      <GalleryWrapper>
        <SectionTitle
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          La Nostra Galleria
        </SectionTitle>
        
        <MainCarousel>
          <AnimatePresence mode="wait">
            <MainImage
              key={mainIndex}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <img 
                src={galleryImages[mainIndex]} 
                alt={`Main Gallery ${mainIndex + 1}`}
                loading="lazy"
                decoding="async"
              />
            </MainImage>
          </AnimatePresence>
          <CarouselButton
            direction="left"
            onClick={prevMainImage}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaChevronLeft />
          </CarouselButton>
          <CarouselButton
            direction="right"
            onClick={nextMainImage}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <FaChevronRight />
          </CarouselButton>
        </MainCarousel>

        <CardGrid
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {cardIndices.map((index) => (
            <Card
              key={index}
              variants={cardVariants}
              whileHover={{ y: -5 }}
              onClick={() => setMainIndex(index)}
            >
              <img 
                src={galleryImages[index]} 
                alt={`Gallery ${index + 1}`}
                loading="lazy"
                decoding="async"
              />
            </Card>
          ))}
        </CardGrid>
      </GalleryWrapper>
    </GalleryContainer>
  );
};

export default GallerySection;
