import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import useCookieConsent from '../../hooks/useCookieConsent';
import CookiePreferences from './CookiePreferences';

const Banner = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(26, 26, 26, 0.95);
  padding: 20px;
  color: white;
  z-index: 1000;
  display: flex;
  align-items: center;
  gap: 15px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 15px;
    gap: 10px;
  }
`;

const Content = styled.div`
  flex: 1;
  font-size: 14px;
  line-height: 1.5;
`;

const PrivacyLink = styled(Link)`
  color: #C19B76;
  text-decoration: underline;
  margin-left: 5px;
  
  &:hover {
    color: #A88B69;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

const Button = styled.button<{ primary?: boolean }>`
  background: ${props => props.primary ? '#C19B76' : 'transparent'};
  color: white;
  border: ${props => props.primary ? 'none' : '1px solid white'};
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  white-space: nowrap;

  &:hover {
    background: ${props => props.primary ? '#A88B69' : 'rgba(255, 255, 255, 0.1)'};
  }

  @media (max-width: 768px) {
    flex: 1;
    text-align: center;
  }
`;

const CookieConsentBanner: React.FC = () => {
  const {
    hasConsent,
    showPreferences,
    handleAcceptAll,
    handleSavePreferences,
    handleOpenPreferences,
    handleClosePreferences,
  } = useCookieConsent();

  if (hasConsent) {
    return null;
  }

  return (
    <>
      <AnimatePresence>
        {!showPreferences && (
          <Banner
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ type: "spring", stiffness: 100 }}
          >
            <Content>
              Utilizziamo i cookie per migliorare la tua esperienza sul nostro sito. Leggi la nostra
              <PrivacyLink to="/privacy-policy">Privacy Policy</PrivacyLink> per saperne di più.
            </Content>
            <ButtonGroup>
              <Button onClick={handleOpenPreferences}>
                Personalizza
              </Button>
              <Button primary onClick={handleAcceptAll}>
                Accetta tutti
              </Button>
            </ButtonGroup>
          </Banner>
        )}
      </AnimatePresence>

      <CookiePreferences
        isOpen={showPreferences}
        onClose={handleClosePreferences}
        onSave={handleSavePreferences}
      />
    </>
  );
};

export default CookieConsentBanner;
