import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Link } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Home/Hero';
import ServiziSection from './components/Servizi/ServiziSection';
import MeteSection from './components/Mete/MeteSection';
import AboutSection from './components/About/AboutSection';
import PreventivoSection from './components/Preventivo/PreventivoSection';
import ContattiSection from './components/Contatti/ContattiSection';
import GallerySection from './components/Gallery/GallerySection';
import Footer from './components/Footer/Footer';
import ScrollToTopButton from './components/ScrollToTop/ScrollToTopButton';
import PageTransition from './components/Transitions/PageTransition';
import { GlobalStyles } from './styles/GlobalStyles';
import { AnimatePresence } from 'framer-motion';
import SEO from './components/SEO/SEO';
import { initializeContentProtection, addConsoleWarning } from './utils/contentProtection';
import CookieConsentBanner from './components/CookieConsent/CookieConsentBanner';
import PrivacyPolicy from './components/Privacy/PrivacyPolicy';

// Scroll to top component
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const HomePage = () => (
  <PageTransition>
    <Hero />
    <ServiziSection />
    <MeteSection />
    <AboutSection />
    <GallerySection />
    <PreventivoSection />
    <ContattiSection />
  </PageTransition>
);

const App = () => {
  const location = useLocation();

  useEffect(() => {
    initializeContentProtection();
    addConsoleWarning();
  }, []);

  return (
    <>
      <GlobalStyles />
      <SEO />
      <ScrollToTop />
      <Navbar />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<HomePage />} />
          <Route path="/servizi" element={
            <PageTransition>
              <Hero />
              <ServiziSection />
            </PageTransition>
          } />
          <Route path="/mete" element={
            <PageTransition>
              <Hero />
              <MeteSection />
            </PageTransition>
          } />
          <Route path="/about" element={
            <PageTransition>
              <Hero />
              <AboutSection />
            </PageTransition>
          } />
          <Route path="/gallery" element={
            <PageTransition>
              <Hero />
              <GallerySection />
            </PageTransition>
          } />
          <Route path="/preventivo" element={
            <PageTransition>
              <Hero />
              <PreventivoSection />
            </PageTransition>
          } />
          <Route path="/contatti" element={
            <PageTransition>
              <Hero />
              <ContattiSection />
            </PageTransition>
          } />
          <Route path="/privacy-policy" element={
            <PageTransition>
              <PrivacyPolicy />
            </PageTransition>
          } />
          <Route path="*" element={
            <PageTransition>
              <div style={{ 
                height: '100vh', 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center',
                background: 'var(--background-color)',
                color: 'var(--text-color)',
                textAlign: 'center',
                padding: '2rem'
              }}>
                <h1 style={{ fontSize: '3rem', marginBottom: '1rem' }}>404</h1>
                <h2 style={{ marginBottom: '2rem' }}>Pagina non trovata</h2>
                <p style={{ marginBottom: '2rem' }}>
                  La pagina che stai cercando non esiste o è stata spostata.
                </p>
                <Link 
                  to="/"
                  style={{
                    padding: '1rem 2rem',
                    background: 'var(--primary-color)',
                    color: 'white',
                    textDecoration: 'none',
                    borderRadius: '5px',
                    fontSize: '1.1rem',
                    transition: 'background 0.3s ease'
                  }}
                >
                  Torna alla Home
                </Link>
              </div>
            </PageTransition>
          } />
        </Routes>
      </AnimatePresence>
      <ScrollToTopButton />
      <Footer />
      <CookieConsentBanner />
    </>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
