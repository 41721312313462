import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

  :root {
    /* Primary Colors */
    --color-primary: #C19B76;      /* Warm Sand - elegant main color */
    --color-primary-light: #D4B498; /* Light Sand */
    --color-primary-dark: #A67F5C;  /* Deep Sand */
    
    /* Secondary Colors */
    --color-secondary: #2A4747;    /* Deep Forest - sophisticated accent */
    --color-secondary-light: #3D6363;
    --color-secondary-dark: #1A2F2F;
    
    /* Accent Colors */
    --color-accent: #E6B325;       /* Safari Gold */
    --color-accent-light: #F4CD66;
    --color-accent-dark: #BF931E;
    
    /* Neutral Colors */
    --color-black: #1C1C1C;        /* Soft Black - for better readability */
    --color-dark: #2D2D2D;
    --color-gray: #6B6B6B;
    --color-light-gray: #F7F5F2;   /* Warm Light Gray */
    --color-white: #FFFFFF;
    
    /* Background Colors */
    --bg-primary: var(--color-white);
    --bg-secondary: var(--color-light-gray);
    --bg-dark: var(--color-black);
    
    /* Text Colors */
    --text-primary: var(--color-black);
    --text-secondary: var(--color-dark);
    --text-light: var(--color-white);
    
    /* UI State Colors */
    --color-success: #4A8F4A;      /* Natural Green */
    --color-warning: #D4A72C;      /* Warm Yellow */
    --color-error: #B54141;        /* Muted Red */
    --color-info: #456789;         /* Cool Blue */
    --success-color: #15803d;
    --success-rgb: 34, 197, 94;
    --error-color: #b91c1c;
    --error-rgb: 239, 68, 68;
    
    /* Typography */
    --font-primary: 'Lato', sans-serif;
    --font-secondary: 'Playfair Display', serif;
    
    /* Font Weights */
    --font-thin: 100;
    --font-light: 300;
    --font-regular: 400;
    --font-bold: 700;
    --font-black: 900;
    
    /* Font Sizes */
    --text-xs: 0.75rem;    /* 12px */
    --text-sm: 0.875rem;   /* 14px */
    --text-base: 1rem;     /* 16px */
    --text-lg: 1.125rem;   /* 18px */
    --text-xl: 1.25rem;    /* 20px */
    --text-2xl: 1.5rem;    /* 24px */
    --text-3xl: 2rem;      /* 32px */
    --text-4xl: 2.5rem;    /* 40px */
    --text-5xl: 3rem;      /* 48px */
    
    /* Spacing */
    --space-1: 0.25rem;    /* 4px */
    --space-2: 0.5rem;     /* 8px */
    --space-3: 0.75rem;    /* 12px */
    --space-4: 1rem;       /* 16px */
    --space-5: 1.25rem;    /* 20px */
    --space-6: 1.5rem;     /* 24px */
    --space-8: 2rem;       /* 32px */
    --space-10: 2.5rem;    /* 40px */
    --space-12: 3rem;      /* 48px */
    
    /* Effects */
    --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
    
    /* Transitions */
    --transition-fast: 150ms ease-in-out;
    --transition-normal: 250ms ease-in-out;
    --transition-slow: 350ms ease-in-out;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
    scroll-behavior: smooth;
  }

  body {
    font-family: var(--font-primary);
    font-size: var(--text-base);
    font-weight: var(--font-regular);
    line-height: 1.5;
    color: var(--text-primary);
    background: url('/images/site-bg.png') repeat;
    background-attachment: fixed;
    position: relative;

    &::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.95);
      z-index: -1;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-primary);
    font-weight: var(--font-bold);
    line-height: 1.2;
    color: var(--text-primary);
    margin-bottom: var(--space-4);
  }

  h1 { font-size: var(--text-5xl); }
  h2 { font-size: var(--text-4xl); }
  h3 { font-size: var(--text-3xl); }
  h4 { font-size: var(--text-2xl); }
  h5 { font-size: var(--text-xl); }
  h6 { font-size: var(--text-lg); }

  p {
    margin-bottom: var(--space-4);
    color: var(--text-secondary);
  }

  a {
    color: var(--color-primary);
    text-decoration: none;
    transition: var(--transition-fast);
    
    &:hover {
      color: var(--color-primary-dark);
    }
  }

  button {
    font-family: var(--font-primary);
    font-weight: var(--font-bold);
    font-size: var(--text-base);
    padding: var(--space-2) var(--space-4);
    cursor: pointer;
    border: none;
    outline: none;
    background-color: var(--color-primary);
    color: var(--color-white);
    border-radius: 4px;
    transition: var(--transition-fast);
    
    &:hover {
      background-color: var(--color-primary-dark);
    }
    
    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
      background-color: var(--color-gray);
    }
  }

  .section {
    padding: var(--space-12) 0;
  }

  .container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 var(--space-4);
  }

  /* Custom Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: var(--bg-secondary);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--color-primary);
    border-radius: 4px;
    
    &:hover {
      background: var(--color-primary-dark);
    }
  }

  /* Google Translate Styles */
  #google_translate_element {
    position: fixed;
    bottom: 25px;
    left: 25px;
    z-index: 9999;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    padding: 8px 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.2);

    &:hover {
      background: rgba(255, 255, 255, 0.15);
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    }
  }

  .goog-te-gadget {
    font-family: inherit !important;
    font-size: 0 !important; /* Hide original text */
  }

  .goog-te-gadget-simple {
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
    border-radius: 8px !important;
    display: flex !important;
    align-items: center !important;
    height: 36px !important;
  }

  .goog-te-gadget-simple img {
    display: none !important;
  }

  .goog-te-menu-value {
    margin: 0 !important;
    color: var(--color-primary) !important;
    font-family: var(--font-primary) !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 0.3px !important;

    span {
      color: var(--color-primary) !important;
      font-family: var(--font-primary) !important;
      border: none !important;
      padding: 0 4px !important;
    }

    /* Hide unnecessary elements */
    span[style*="border-left"] {
      display: none !important;
    }
  }

  /* Hide the top bar */
  .goog-te-banner-frame {
    display: none !important;
  }

  .goog-te-menu2 {
    background-color: rgba(255, 255, 255, 0.98) !important;
    border: none !important;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
    border-radius: 12px !important;
    padding: 8px !important;
  }

  .goog-te-menu2-item div, .goog-te-menu2-item:link div, .goog-te-menu2-item:visited div, .goog-te-menu2-item:active div {
    color: var(--text-primary) !important;
    font-family: var(--font-primary) !important;
    font-size: 14px !important;
    padding: 8px 12px !important;
    border-radius: 6px !important;
    transition: all 0.2s ease !important;
  }

  .goog-te-menu2-item:hover div {
    background-color: rgba(var(--color-primary-rgb), 0.1) !important;
    color: var(--color-primary) !important;
  }

  .goog-te-menu2-item-selected div {
    background-color: rgba(var(--color-primary-rgb), 0.15) !important;
    color: var(--color-primary) !important;
    font-weight: 500 !important;
  }

  /* Fix for page shifting */
  body {
    top: 0 !important;
  }

  /* Content Protection Styles */
  img {
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
  }

  /* Prevent text selection */
  .prevent-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Custom selection color */
  ::selection {
    background: rgba(193, 155, 118, 0.3);
    color: inherit;
  }

  /* Firefox */
  ::-moz-selection {
    background: rgba(193, 155, 118, 0.3);
    color: inherit;
  }

  /* Utility Classes */
  .text-primary { color: var(--color-primary); }
  .text-secondary { color: var(--color-secondary); }
  .text-accent { color: var(--color-accent); }
  .text-dark { color: var(--text-primary); }
  .text-light { color: var(--text-light); }

  .bg-primary { background-color: var(--bg-primary); }
  .bg-secondary { background-color: var(--bg-secondary); }
  .bg-dark { background-color: var(--bg-dark); }

  .font-thin { font-weight: var(--font-thin); }
  .font-light { font-weight: var(--font-light); }
  .font-regular { font-weight: var(--font-regular); }
  .font-bold { font-weight: var(--font-bold); }
  .font-black { font-weight: var(--font-black); }

  @media (max-width: 768px) {
    html {
      font-size: 14px;
    }
    
    .container {
      width: 95%;
      padding: 0 var(--space-3);
    }
    
    .section {
      padding: var(--space-8) 0;
    }
  }
`;
