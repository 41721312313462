import { useState, useEffect } from 'react';

interface CookiePreferences {
  necessary: boolean;
  functional: boolean;
  analytics: boolean;
  marketing: boolean;
}

const DEFAULT_PREFERENCES: CookiePreferences = {
  necessary: true,
  functional: false,
  analytics: false,
  marketing: false,
};

export const useCookieConsent = () => {
  const [hasConsent, setHasConsent] = useState<boolean>(false);
  const [showPreferences, setShowPreferences] = useState<boolean>(false);
  const [preferences, setPreferences] = useState<CookiePreferences>(DEFAULT_PREFERENCES);

  useEffect(() => {
    const savedConsent = localStorage.getItem('cookie-consent');
    const savedPreferences = localStorage.getItem('cookie-preferences');

    if (savedConsent === 'true' && savedPreferences) {
      setHasConsent(true);
      setPreferences(JSON.parse(savedPreferences));
    } else {
      // Mostra il banner se non c'è consenso
      setTimeout(() => {
        setShowPreferences(true);
      }, 1000);
    }
  }, []);

  const handleAcceptAll = () => {
    const allEnabled = {
      necessary: true,
      functional: true,
      analytics: true,
      marketing: true,
    };

    setPreferences(allEnabled);
    setHasConsent(true);
    setShowPreferences(false);
    
    localStorage.setItem('cookie-consent', 'true');
    localStorage.setItem('cookie-preferences', JSON.stringify(allEnabled));
    
    // Inizializza i servizi in base alle preferenze
    initializeServices(allEnabled);
  };

  const handleSavePreferences = (newPreferences: Partial<CookiePreferences>) => {
    const updatedPreferences = {
      ...preferences,
      ...newPreferences,
      necessary: true, // I cookie necessari sono sempre abilitati
    };

    setPreferences(updatedPreferences);
    setHasConsent(true);
    setShowPreferences(false);
    
    localStorage.setItem('cookie-consent', 'true');
    localStorage.setItem('cookie-preferences', JSON.stringify(updatedPreferences));
    
    // Inizializza i servizi in base alle preferenze
    initializeServices(updatedPreferences);
  };

  const handleOpenPreferences = () => {
    setShowPreferences(true);
  };

  const handleClosePreferences = () => {
    setShowPreferences(false);
  };

  const initializeServices = (prefs: CookiePreferences) => {
    // Inizializza i servizi in base alle preferenze
    if (prefs.analytics) {
      // Inizializza Google Analytics o altri servizi di analytics
      // window.gtag('consent', 'update', { analytics_storage: 'granted' });
    }

    if (prefs.marketing) {
      // Inizializza servizi di marketing
      // window.gtag('consent', 'update', { ad_storage: 'granted' });
    }

    if (prefs.functional) {
      // Inizializza servizi funzionali
      // Per esempio, chat widgets, preferenze utente, ecc.
    }
  };

  return {
    hasConsent,
    showPreferences,
    preferences,
    handleAcceptAll,
    handleSavePreferences,
    handleOpenPreferences,
    handleClosePreferences,
  };
};

export default useCookieConsent;
