import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { 
  FaUser, 
  FaEnvelope, 
  FaPhone, 
  FaUsers, 
  FaCalendarAlt, 
  FaPlaneDeparture, 
  FaMoneyBillWave,
  FaMapMarkedAlt,
  FaCommentAlt,
  FaList,
  FaHotel,
  FaCar,
  FaUtensils,
  FaCamera,
  FaLandmark,
  FaYinYang,
  FaUmbrellaBeach,
  FaHeart
} from 'react-icons/fa';
import emailjs from '@emailjs/browser';

// Initialize EmailJS with environment variable
emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY || '');

// Constants for EmailJS from environment variables
const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID || '';
const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID || '';
const RECIPIENT_EMAIL = 'info@nellosafari.com';

// Validation for EmailJS configuration
if (!EMAILJS_SERVICE_ID || !EMAILJS_TEMPLATE_ID || !process.env.REACT_APP_EMAILJS_PUBLIC_KEY) {
  console.error('EmailJS configuration is missing. Please check your environment variables.');
}

// Type definitions
type FormField = string | number | boolean | { [key: string]: FormField };
type NestedFormData = {
  nomeCompleto: string;
  email: string;
  telefono: string;
  partecipanti: {
    adulti: number;
    minori: number;
    hasMinori: boolean;
  };
  date: {
    arrivo: string;
    ritorno: string;
  };
  aeroporto: string;
  budget: string;
  servizi: string[];
  messaggio: string;
};

type FormDataKey = keyof NestedFormData;
type NestedKey<T> = T extends object ? keyof T : never;

// Styled Components
const FormContainer = styled.div`
  max-width: 1200px;
  width: 95%;
  margin: 2rem auto;
  padding: 2.5rem;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);

  @media (max-width: 768px) {
    padding: 1.5rem;
    margin: 1rem auto;
    width: 92%;
  }

  @media (max-width: 480px) {
    padding: 1rem;
    margin: 0.5rem auto;
    width: 90%;
  }
`;

const FormSection = styled(motion.div)`
  position: relative;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.07),
    inset 0 0 0 1px rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.7);
  transform-style: preserve-3d;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 2.5rem;
  margin-bottom: 2.5rem;

  @media (max-width: 768px) {
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: 20px;
  }

  @media (max-width: 480px) {
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 15px;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.92);
    box-shadow: 
      0 20px 40px rgba(0, 0, 0, 0.12),
      0 5px 15px rgba(0, 0, 0, 0.08),
      inset 0 0 0 1px rgba(255, 255, 255, 1);
    transform: translateY(-10px);

    @media (max-width: 768px) {
      transform: translateY(-5px);
    }
  }
`;

const SectionTitle = styled(motion.h2)`
  font-size: 1.8rem;
  color: #1a1a1a;
  margin: 0 0 2rem 0;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #C19B76 0%, #8B6343 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 0.5rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;

  svg {
    color: #C19B76;
    font-size: 1.8rem;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background: #C19B76;
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100px;
  }
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  @media (max-width: 480px) {
    gap: 1rem;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 1rem 1.5rem;
  border-radius: 15px;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(193, 155, 118, 0.2);
  box-shadow: 
    0 4px 6px rgba(0, 0, 0, 0.05),
    inset 0 0 0 1px rgba(255, 255, 255, 0.7);
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);

  @media (max-width: 768px) {
    padding: 0.875rem 1.25rem;
    font-size: 0.95rem;
  }

  @media (max-width: 480px) {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.95);
    border-color: rgba(193, 155, 118, 0.4);
    transform: translateY(-2px);
    box-shadow: 
      0 6px 12px rgba(0, 0, 0, 0.08),
      inset 0 0 0 1px rgba(255, 255, 255, 0.8);
  }

  &:focus {
    outline: none;
    border-color: #C19B76;
    background: rgba(255, 255, 255, 1);
    box-shadow: 
      0 8px 16px rgba(193, 155, 118, 0.15),
      inset 0 0 0 1px rgba(255, 255, 255, 0.9);
    transform: translateY(-2px);
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 1rem 1.5rem;
  border-radius: 15px;
  font-size: 1rem;
  min-height: 150px;
  resize: vertical;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(193, 155, 118, 0.2);
  box-shadow: 
    0 4px 6px rgba(0, 0, 0, 0.05),
    inset 0 0 0 1px rgba(255, 255, 255, 0.7);
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  max-height: 500px;

  @media (max-width: 768px) {
    padding: 0.875rem 1.25rem;
    font-size: 0.95rem;
  }

  @media (max-width: 480px) {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.95);
    border-color: rgba(193, 155, 118, 0.4);
    transform: translateY(-2px);
    box-shadow: 
      0 6px 12px rgba(0, 0, 0, 0.08),
      inset 0 0 0 1px rgba(255, 255, 255, 0.8);
  }

  &:focus {
    outline: none;
    border-color: #C19B76;
    background: rgba(255, 255, 255, 1);
    box-shadow: 
      0 8px 16px rgba(193, 155, 118, 0.15),
      inset 0 0 0 1px rgba(255, 255, 255, 0.9);
    transform: translateY(-2px);
  }
`;

const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ServiceCategory = styled.div`
  h2 {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: #c19b76;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    
    svg {
      color: #c19b76;
    }
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 0.875rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }
`;

const ServiceCard = styled.div<{ selected: boolean }>`
  position: relative;
  border-radius: 20px;
  background: ${props => props.selected ? 'rgba(193, 155, 118, 0.1)' : 'rgba(255, 255, 255, 0.85)'};
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  box-shadow: 
    0 8px 20px rgba(0, 0, 0, 0.07),
    0 2px 8px rgba(0, 0, 0, 0.04),
    inset 0 0 0 1px ${props => props.selected ? 'rgba(193, 155, 118, 0.4)' : 'rgba(255, 255, 255, 0.7)'};
  border: 1px solid ${props => props.selected ? '#C19B76' : 'rgba(255, 255, 255, 0.7)'};
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  display: flex;
  align-items: center;
  gap: 1rem;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 1.25rem;
    border-radius: 15px;
  }

  @media (max-width: 480px) {
    padding: 1rem;
    border-radius: 12px;
  }

  &:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.92);
    box-shadow: 
      0 15px 30px rgba(0, 0, 0, 0.1),
      0 4px 10px rgba(0, 0, 0, 0.05),
      inset 0 0 0 1px rgba(193, 155, 118, 0.3);

    @media (max-width: 768px) {
      transform: translateY(-3px);
    }
  }
`;

const ServiceTitle = styled.h3`
  margin: 0;
  font-size: 1.1rem;
  color: #1a1a1a;
  font-weight: 500;
  position: relative;
  z-index: 3;
`;

const FormSelect = styled.select`
  width: 100%;
  padding: 1rem 1.5rem;
  border-radius: 15px;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(193, 155, 118, 0.2);
  box-shadow: 
    0 4px 6px rgba(0, 0, 0, 0.05),
    inset 0 0 0 1px rgba(255, 255, 255, 0.7);
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23c19b76' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1.5rem center;
  padding-right: 3.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 0.875rem 1.25rem;
    font-size: 0.95rem;
  }

  @media (max-width: 480px) {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.95);
    border-color: rgba(193, 155, 118, 0.4);
    transform: translateY(-2px);
    box-shadow: 
      0 6px 12px rgba(0, 0, 0, 0.08),
      inset 0 0 0 1px rgba(255, 255, 255, 0.8);
  }

  &:focus {
    outline: none;
    border-color: #C19B76;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 
      0 8px 16px rgba(193, 155, 118, 0.15),
      inset 0 0 0 1px rgba(255, 255, 255, 0.9);
    transform: translateY(-2px);
  }
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #000000;
  font-weight: 500;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.95rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const RequiredMark = styled.span`
  color: #c19b76;
  margin-left: 4px;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;

  @media (max-width: 768px) {
    margin-top: 1.5rem;
  }

  @media (max-width: 480px) {
    margin-top: 1rem;
  }
`;

const buttonVariants = {
  idle: {
    scale: 1,
    backgroundColor: "linear-gradient(135deg, #C19B76 0%, #8B6343 100%)"
  },
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.3,
      ease: "easeInOut"
    }
  },
  tap: {
    scale: 0.95
  },
  submitting: {
    scale: 0.98,
    opacity: 0.8
  }
};

const submitTextVariants = {
  idle: { opacity: 1 },
  submitting: { opacity: 0 }
};

const loadingSpinnerVariants = {
  submitting: {
    opacity: 1,
    rotate: 360,
    transition: {
      rotate: {
        duration: 1,
        ease: "linear",
        repeat: Infinity
      }
    }
  },
  idle: { opacity: 0 }
};

const SubmitButton = styled(motion.button)`
  background: linear-gradient(135deg, #C19B76 0%, #8B6343 100%);
  color: white;
  border: none;
  padding: 1.2rem 3.5rem;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(193, 155, 118, 0.3);
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #8B6343 0%, #C19B76 100%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    padding: 1rem 2.5rem;
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    padding: 0.875rem 2rem;
    font-size: 0.95rem;
    width: 100%;
    max-width: 280px;
  }
`;

const LoadingSpinner = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
`;

const ButtonText = styled(motion.span)`
  position: relative;
  z-index: 1;
  display: inline-block;
`;

const RequiredFieldsText = styled.p`
  text-align: center;
  color: rgba(139, 99, 67, 0.7);
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
`;

const PageTitle = styled(motion.h1)`
  text-align: center;
  color: #000000;
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: linear-gradient(135deg, #C19B76 0%, #8B6343 100%);
    border-radius: 2px;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }
`;

// Main Component
const PreventivoSection: React.FC = () => {
  const [formData, setFormData] = useState<NestedFormData>({
    nomeCompleto: '',
    email: '',
    telefono: '',
    partecipanti: {
      adulti: 1,
      minori: 0,
      hasMinori: false
    },
    date: {
      arrivo: '',
      ritorno: ''
    },
    aeroporto: '',
    budget: '',
    servizi: [],
    messaggio: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const formRef = useRef<HTMLFormElement>(null);
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    
    if (name.includes('.')) {
      const [parent, child] = name.split('.') as [FormDataKey, string];
      const parentValue = formData[parent];
      
      if (typeof parentValue === 'object' && parentValue !== null) {
        setFormData(prev => ({
          ...prev,
          [parent]: {
            ...parentValue,
            [child]: type === 'number' ? Number(value) : value
          }
        }));
      }
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'number' ? Number(value) : value
      }));
    }
  };

  const handleServiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      servizi: checked 
        ? [...prev.servizi, value]
        : prev.servizi.filter(id => id !== value)
    }));
  };

  const handleMinoriToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFormData(prev => ({
      ...prev,
      partecipanti: {
        ...prev.partecipanti,
        hasMinori: checked,
        minori: checked ? prev.partecipanti.minori : 0
      }
    }));
  };

  const validateForm = (): boolean => {
    // Required fields validation
    if (!formData.nomeCompleto || !formData.email || !formData.telefono) {
      alert('Per favore, compila tutti i campi obbligatori nella sezione informazioni personali.');
      return false;
    }

    if (!formData.date.arrivo || !formData.date.ritorno) {
      alert('Per favore, seleziona le date di arrivo e ritorno.');
      return false;
    }

    if (formData.partecipanti.adulti < 1) {
      alert('Il numero di adulti deve essere almeno 1.');
      return false;
    }

    if (!formData.budget) {
      alert('Per favore, seleziona un budget indicativo.');
      return false;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      alert('Per favore, inserisci un indirizzo email valido.');
      return false;
    }

    // Phone validation (optional but recommended)
    const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    if (!phoneRegex.test(formData.telefono)) {
      alert('Per favore, inserisci un numero di telefono valido.');
      return false;
    }

    // Date validation
    const arrivo = new Date(formData.date.arrivo);
    const ritorno = new Date(formData.date.ritorno);
    const oggi = new Date();

    if (arrivo < oggi) {
      alert('La data di arrivo non può essere nel passato.');
      return false;
    }

    if (ritorno <= arrivo) {
      alert('La data di ritorno deve essere successiva alla data di arrivo.');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    setSubmitStatus('idle');

    try {
      // Format date strings
      const formatDate = (dateStr: string) => {
        const date = new Date(dateStr);
        return date.toLocaleDateString('it-IT', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
      };

      const templateParams = {
        to_email: RECIPIENT_EMAIL,
        reply_to: formData.email,
        from_name: formData.nomeCompleto,
        to_name: 'Nello Safari',
        subject: `Nuova Richiesta Preventivo da ${formData.nomeCompleto}`,
        message: `
DETTAGLI RICHIESTA PREVENTIVO:

👤 INFORMAZIONI PERSONALI
Nome Completo: ${formData.nomeCompleto}
Email: ${formData.email}
Telefono: ${formData.telefono}

👥 PARTECIPANTI
Adulti: ${formData.partecipanti.adulti}
${formData.partecipanti.hasMinori ? `Minori: ${formData.partecipanti.minori}` : ''}

📅 DATE DEL VIAGGIO
Arrivo: ${formatDate(formData.date.arrivo)}
Ritorno: ${formatDate(formData.date.ritorno)}

✈️ AEROPORTO
${formData.aeroporto}

💰 BUDGET
${formData.budget}

🎯 SERVIZI RICHIESTI
${formData.servizi.join('\n')}

📝 MESSAGGIO AGGIUNTIVO
${formData.messaggio}

--
Inviato da: Modulo Preventivo NelloSafari.com
        `.trim()
      };

      const response = await emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        templateParams
      );

      if (response.status === 200) {
        setSubmitStatus('success');
        alert('Grazie! La tua richiesta è stata inviata con successo. Ti contatteremo presto!');
        
        // Reset form
        setFormData({
          nomeCompleto: '',
          email: '',
          telefono: '',
          partecipanti: {
            adulti: 1,
            minori: 0,
            hasMinori: false
          },
          date: {
            arrivo: '',
            ritorno: ''
          },
          aeroporto: '',
          budget: '',
          servizi: [],
          messaggio: ''
        });
      } else {
        throw new Error('Errore nell\'invio del messaggio');
      }
    } catch (error) {
      console.error('Errore durante l\'invio del form:', error);
      setSubmitStatus('error');
      alert('Si è verificato un errore durante l\'invio. Per favore riprova più tardi o contattaci direttamente via email.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const budgetOptions = [
    { id: 'economico', label: 'max €1,500 per persona' },
    { id: 'medio', label: 'max €3,000 per persona' },
    { id: 'lusso', label: '€5,000+ per persona' },
    { id: 'nessuno', label: 'Nessun Budget' },
  ];

  const serviceCategories = {
    'Safari & Avventura': { icon: FaCamera },
    'Alloggio': { icon: FaHotel },
    'Trasporti': { icon: FaCar },
    'Cibo & Bevande': { icon: FaUtensils },
    'Cultura & Tour': { icon: FaLandmark },
    'Sport & Benessere': { icon: FaYinYang },
    'Mare & Attività': { icon: FaUmbrellaBeach },
    'Esperienze Sociali': { icon: FaHeart }
  };

  const servizi = [
    // Safari & Avventura
    { id: 'safari-1', name: 'Safari', category: 'Safari & Avventura' },
    { id: 'safari-2', name: 'Escursioni', category: 'Safari & Avventura' },
    
    // Alloggio
    { id: 'alloggio-1', name: 'Alloggio Villa', category: 'Alloggio' },
    { id: 'alloggio-2', name: 'Alloggio Resort/Hotel', category: 'Alloggio' },
    
    // Trasporti
    { id: 'trasporto-1', name: 'Autista Privato', category: 'Trasporti' },
    { id: 'trasporto-2', name: 'Shuttle Service Aeroporto', category: 'Trasporti' },
    
    // Cibo & Bevande
    { id: 'food-1', name: 'Chef Privato (Cibo & Bevande inclusi)', category: 'Cibo & Bevande' },
    { id: 'food-2', name: 'Cooking Class', category: 'Cibo & Bevande' },
    
    // Cultura & Tour
    { id: 'cultura-1', name: 'Centro Culturale', category: 'Cultura & Tour' },
    { id: 'cultura-2', name: 'Esperienze Tribali', category: 'Cultura & Tour' },
    { id: 'cultura-3', name: 'Tour Cittadini', category: 'Cultura & Tour' },
    { id: 'cultura-4', name: 'Tour Culturali', category: 'Cultura & Tour' },
    
    // Sport & Benessere
    { id: 'sport-1', name: 'Yoga', category: 'Sport & Benessere' },
    { id: 'sport-2', name: 'Padel', category: 'Sport & Benessere' },
    { id: 'sport-3', name: 'Golf', category: 'Sport & Benessere' },
    { id: 'sport-4', name: 'Giro in Mountain Bike', category: 'Sport & Benessere' },
    
    // Mare & Attività
    { id: 'mare-1', name: 'Tamani Jua', category: 'Mare & Attività' },
    { id: 'mare-2', name: 'Kitesurf', category: 'Mare & Attività' },
    { id: 'mare-3', name: 'SUP', category: 'Mare & Attività' },
    { id: 'mare-4', name: 'Diving', category: 'Mare & Attività' },
    
    // Esperienze Sociali
    { id: 'social-1', name: 'Visita Orfanotrofio', category: 'Esperienze Sociali' },
    { id: 'social-2', name: 'Visita Villaggio', category: 'Esperienze Sociali' }
  ];

  const renderServices = () => {
    return (
      <ServicesContainer>
        {Object.entries(serviceCategories).map(([categoryName, categoryData]) => (
          <ServiceCategory key={categoryName}>
            <h2>
              {React.createElement(categoryData.icon)}
              {categoryName}
            </h2>
            <ServicesGrid>
              {servizi
                .filter(servizio => servizio.category === categoryName)
                .map(servizio => {
                  const isSelected = formData.servizi.includes(servizio.id);
                  return (
                    <ServiceCard 
                      key={servizio.id} 
                      selected={isSelected}
                      onClick={() => {
                        setFormData(prev => ({
                          ...prev,
                          servizi: isSelected
                            ? prev.servizi.filter(id => id !== servizio.id)
                            : [...prev.servizi, servizio.id]
                        }));
                      }}
                    >
                      <input
                        type="checkbox"
                        name="servizi"
                        value={servizio.id}
                        checked={isSelected}
                        onChange={handleServiceChange}
                      />
                      <ServiceTitle>{servizio.name}</ServiceTitle>
                    </ServiceCard>
                  );
                })}
            </ServicesGrid>
          </ServiceCategory>
        ))}
      </ServicesContainer>
    );
  };

  const formSectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  const titleVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  return (
    <FormContainer ref={ref}>
      <motion.form
        ref={formRef}
        onSubmit={handleSubmit}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <PageTitle
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, y: -20 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.6,
                ease: "easeOut"
              }
            }
          }}
        >
          Richiedi Preventivo
        </PageTitle>
        <RequiredFieldsText>
          Compila il form preventivo con i dettagli del tuo viaggio. Una volta completato il form, la tua richiesta verrà immediatamente inoltrata al nostro team.
        </RequiredFieldsText>
        {/* Sezione 1: Dati Personali */}
        <FormSection
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={formSectionVariants}
        >
          <SectionTitle
            variants={titleVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <FaUser />
            Dati Personali
          </SectionTitle>
          <FormGrid>
            <FormGroup>
              <FormLabel>Nome Completo<RequiredMark>*</RequiredMark></FormLabel>
              <FormInput
                type="text"
                name="nomeCompleto"
                value={formData.nomeCompleto}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Email<RequiredMark>*</RequiredMark></FormLabel>
              <FormInput
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Telefono<RequiredMark>*</RequiredMark></FormLabel>
              <FormInput
                type="tel"
                name="telefono"
                value={formData.telefono}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
          </FormGrid>
        </FormSection>

        {/* Sezione 2: Dettagli del Viaggio */}
        <FormSection
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={formSectionVariants}
        >
          <SectionTitle
            variants={titleVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <FaMapMarkedAlt />
            Dettagli del Viaggio
          </SectionTitle>
          <FormGrid>
            <FormGroup>
              <FormLabel>Numero Partecipanti<RequiredMark>*</RequiredMark></FormLabel>
              <FormInput
                type="number"
                name="partecipanti.adulti"
                value={formData.partecipanti.adulti}
                onChange={handleInputChange}
                min="1"
                required
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Minori (under 14)</FormLabel>
              <FormInput
                type="number"
                name="partecipanti.minori"
                value={formData.partecipanti.minori}
                onChange={handleInputChange}
                min="0"
                disabled={!formData.partecipanti.hasMinori}
              />
              <input
                type="checkbox"
                name="partecipanti.hasMinori"
                checked={formData.partecipanti.hasMinori}
                onChange={handleMinoriToggle}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Data Arrivo<RequiredMark>*</RequiredMark></FormLabel>
              <FormInput
                type="date"
                name="date.arrivo"
                value={formData.date.arrivo}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Data Ritorno<RequiredMark>*</RequiredMark></FormLabel>
              <FormInput
                type="date"
                name="date.ritorno"
                value={formData.date.ritorno}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Aeroporto di Arrivo</FormLabel>
              <FormInput
                type="text"
                name="aeroporto"
                value={formData.aeroporto}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Budget<RequiredMark>*</RequiredMark></FormLabel>
              <FormSelect
                name="budget"
                value={formData.budget}
                onChange={handleInputChange}
                required
              >
                <option value="">Seleziona Budget</option>
                {budgetOptions.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.label}
                  </option>
                ))}
              </FormSelect>
            </FormGroup>
          </FormGrid>
        </FormSection>

        {/* Sezione 3: Attività e Servizi */}
        <FormSection
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={formSectionVariants}
        >
          <SectionTitle
            variants={titleVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <FaList />
            Attività e Servizi
          </SectionTitle>
          {renderServices()}
        </FormSection>

        {/* Sezione 4: Dettagli Aggiuntivi */}
        <FormSection
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={formSectionVariants}
        >
          <SectionTitle
            variants={titleVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <FaCommentAlt />
            Aggiungi Maggiori Dettagli
          </SectionTitle>
          <FormGroup>
            <TextArea
              name="messaggio"
              value={formData.messaggio}
              onChange={handleInputChange}
              placeholder="Inserisci qui ulteriori dettagli o richieste specifiche (max 3000 parole)"
              maxLength={3000}
            />
          </FormGroup>
        </FormSection>

        {/* Submit Button */}
        <SubmitButtonContainer>
          <RequiredFieldsText>
            *inserisci campi obbligatori
          </RequiredFieldsText>
          <SubmitButton
            type="submit"
            disabled={isSubmitting}
            variants={buttonVariants}
            initial="idle"
            whileHover="hover"
            whileTap="tap"
            animate={isSubmitting ? "submitting" : "idle"}
          >
            <ButtonText variants={submitTextVariants}>
              {isSubmitting ? "" : "Invia richiesta"}
            </ButtonText>
            <LoadingSpinner variants={loadingSpinnerVariants} />
          </SubmitButton>
        </SubmitButtonContainer>
      </motion.form>
    </FormContainer>
  );
};

export default PreventivoSection;
