import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaWhatsapp, FaEnvelope, FaPhone, FaMapMarkerAlt, FaChevronRight } from 'react-icons/fa';
import { motion } from 'framer-motion';

const FooterContainer = styled.footer`
  background: linear-gradient(
    to bottom,
    rgba(var(--background-color-rgb), 0.97),
    rgba(var(--footer-bg-color-rgb), 0.97)
  );
  color: var(--text-color);
  padding: 80px 0 40px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      to right,
      transparent,
      rgba(var(--primary-color-rgb), 0.3),
      transparent
    );
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/images/footer-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.1;
    z-index: -1;
    filter: grayscale(40%) brightness(40%);
  }
`;

const FooterContent = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  gap: 60px;
  z-index: 1;

  @media (max-width: 968px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 40px;
  }
`;

const FooterSection = styled.div`
  h3 {
    color: var(--primary-color);
    font-size: 1.8rem;
    margin-bottom: 25px;
    font-weight: 600;
    position: relative;
    padding-bottom: 15px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50px;
      height: 2px;
      background: var(--primary-color);
      transition: width 0.3s ease;
    }

    @media (max-width: 768px) {
      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &:hover h3::after {
    width: 75px;
  }

  p {
    margin-bottom: 20px;
    line-height: 1.8;
    color: var(--text-color);
    font-size: 1.1rem;
    opacity: 0.9;
  }
`;

const QuickLinks = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const LinkItem = styled.li`
  transition: transform 0.3s ease;

  &:hover {
    transform: translateX(5px);

    @media (max-width: 768px) {
      transform: translateY(-2px);
    }
  }

  a {
    color: var(--text-color);
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1.1rem;
    opacity: 0.9;
    transition: all 0.3s ease;

    &:hover {
      color: var(--primary-color);
      opacity: 1;
    }

    svg {
      font-size: 0.8rem;
      color: var(--primary-color);
    }

    @media (max-width: 768px) {
      justify-content: center;
    }
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 25px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const SocialIcon = styled(motion.a)`
  color: var(--text-color);
  font-size: 1.8rem;
  opacity: 0.9;
  transition: all 0.3s ease;

  &:hover {
    color: var(--primary-color);
    opacity: 1;
    transform: translateY(-3px);
  }
`;

const ContactItem = styled(motion.div)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 15px;

  svg {
    color: var(--primary-color);
    font-size: 1.4rem;
  }

  a, p {
    color: var(--text-color);
    text-decoration: none;
    font-size: 1.1rem;
    opacity: 0.9;
    transition: all 0.3s ease;
    margin: 0;
  }

  a:hover {
    color: var(--primary-color);
    opacity: 1;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 60px;
  padding-top: 30px;
  border-top: 1px solid rgba(var(--text-color-rgb), 0.1);
  color: var(--text-color);
  font-size: 1rem;
  opacity: 0.8;

  a {
    color: var(--primary-color);
    text-decoration: none;
    margin-left: 5px;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const CompanyDescription = styled.div`
  max-width: 500px;

  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;

const Footer = () => {
  const contactVariants = {
    hover: {
      x: 5,
      transition: { duration: 0.3 }
    }
  };

  const socialVariants = {
    hover: {
      y: -3,
      transition: { duration: 0.3 }
    }
  };

  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <h3>NelloSafari</h3>
          <CompanyDescription>
            <p>
              La tua guida di fiducia per esplorare le meraviglie del Kenya.
              Offriamo esperienze uniche e indimenticabili, combinando avventura,
              comfort e sicurezza per rendere il tuo safari un ricordo indelebile.
            </p>
          </CompanyDescription>
          <SocialLinks>
            <SocialIcon 
              href="https://facebook.com/nellosafari" 
              target="_blank" 
              rel="noopener noreferrer"
              variants={socialVariants}
              whileHover="hover"
            >
              <FaFacebook />
            </SocialIcon>
            <SocialIcon 
              href="https://instagram.com/nellosafari" 
              target="_blank" 
              rel="noopener noreferrer"
              variants={socialVariants}
              whileHover="hover"
            >
              <FaInstagram />
            </SocialIcon>
            <SocialIcon 
              href="https://wa.me/254746473595" 
              target="_blank" 
              rel="noopener noreferrer"
              variants={socialVariants}
              whileHover="hover"
            >
              <FaWhatsapp />
            </SocialIcon>
          </SocialLinks>
        </FooterSection>

        <FooterSection>
          <h3>Link Utili</h3>
          <QuickLinks>
            <LinkItem>
              <Link to="/"><FaChevronRight />Home</Link>
            </LinkItem>
            <LinkItem>
              <Link to="/about"><FaChevronRight />Chi Siamo</Link>
            </LinkItem>
            <LinkItem>
              <Link to="/servizi"><FaChevronRight />Servizi</Link>
            </LinkItem>
            <LinkItem>
              <Link to="/mete"><FaChevronRight />Destinazioni</Link>
            </LinkItem>
            <LinkItem>
              <Link to="/gallery"><FaChevronRight />Gallery</Link>
            </LinkItem>
            <LinkItem>
              <Link to="/preventivo"><FaChevronRight />Preventivo</Link>
            </LinkItem>
            <LinkItem>
              <Link to="/contatti"><FaChevronRight />Contatti</Link>
            </LinkItem>
          </QuickLinks>
        </FooterSection>

        <FooterSection>
          <h3>Contatti</h3>
          <ContactItem 
            variants={contactVariants}
            whileHover="hover"
          >
            <FaPhone />
            <a href="tel:+254746473595">+254 746 473 595</a>
          </ContactItem>
          <ContactItem 
            variants={contactVariants}
            whileHover="hover"
          >
            <FaEnvelope />
            <a href="mailto:info@nellosafari.com">info@nellosafari.com</a>
          </ContactItem>
          <ContactItem 
            variants={contactVariants}
            whileHover="hover"
          >
            <FaMapMarkerAlt />
            <p>Malindi, P.O Box 381, Kenya</p>
          </ContactItem>
        </FooterSection>
      </FooterContent>

      <Copyright>
        &copy; {new Date().getFullYear()} NelloSafari. Tutti i diritti riservati.
        <Link to="/privacy-policy">Privacy Policy</Link>
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
