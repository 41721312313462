import React, { useEffect } from 'react';

interface SEOProps {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  url?: string;
}

const SEO: React.FC<SEOProps> = ({
  title = 'NelloSafari - Safari ed Escursioni in Kenya',
  description = 'Scopri il Kenya autentico con NelloSafari. Safari personalizzati, guide esperte e destinazioni uniche. Vivi un\'esperienza indimenticabile nella savana africana.',
  keywords = 'safari kenya, vacanze kenya, safari africa, tour kenya, masai mara, tsavo, amboseli, guida safari, safari personalizzati, safari lusso kenya, wildlife, safari',
  image = '/images/og-image.jpg',
  url = 'https://www.nellosafari.com'
}) => {
  useEffect(() => {
    // Update meta tags
    document.title = title;
    
    // Update meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement('meta');
      metaDescription.setAttribute('name', 'description');
      document.head.appendChild(metaDescription);
    }
    metaDescription.setAttribute('content', description);
    
    // Update meta keywords
    let metaKeywords = document.querySelector('meta[name="keywords"]');
    if (!metaKeywords) {
      metaKeywords = document.createElement('meta');
      metaKeywords.setAttribute('name', 'keywords');
      document.head.appendChild(metaKeywords);
    }
    metaKeywords.setAttribute('content', keywords);
    
    // Update Open Graph meta tags
    const ogTags = {
      'og:title': title,
      'og:description': description,
      'og:image': image,
      'og:url': url,
      'og:type': 'website'
    };
    
    Object.entries(ogTags).forEach(([property, content]) => {
      let metaTag = document.querySelector(`meta[property="${property}"]`);
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.setAttribute('property', property);
        document.head.appendChild(metaTag);
      }
      metaTag.setAttribute('content', content);
    });
  }, [title, description, keywords, image, url]);

  return null;
};

export default SEO;
