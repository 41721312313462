import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaWhatsapp, FaFacebook, FaInstagram } from 'react-icons/fa';
import emailjs from '@emailjs/browser';

const ContattiContainer = styled.section`
  padding: 100px 0;
  background: var(--background-color);
`;

const ContattiContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const SectionTitle = styled(motion.h2)`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--text-color);
`;

const SectionSubtitle = styled(motion.p)`
  text-align: center;
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 4rem;
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const InfoCard = styled(motion.div)`
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 1.5rem;
  flex-shrink: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const InfoContent = styled.div``;

const InfoTitle = styled.h4`
  font-size: 1.2rem;
  color: var(--text-color);
  margin-bottom: 0.5rem;
`;

const InfoText = styled.p`
  color: #666;
  line-height: 1.6;
`;

const ContactLink = styled.a`
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: var(--accent-color);
  }
`;

const FormContainer = styled(motion.div)`
  background: white;
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 2rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-color);
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border: 2px solid #e9ecef;
  border-radius: 10px;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: var(--primary-color);
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 1rem;
  border: 2px solid #e9ecef;
  border-radius: 10px;
  font-size: 1rem;
  min-height: 150px;
  resize: vertical;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: var(--primary-color);
  }
`;

const SubmitButton = styled(motion.button)`
  background: linear-gradient(135deg, #C19B76 0%, #8B6343 100%);
  color: white;
  border: none;
  padding: 1.2rem 3.5rem;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(193, 155, 118, 0.3);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(193, 155, 118, 0.4);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    padding: 1rem 2.5rem;
    font-size: 1rem;
  }
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const LoadingSpinner = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
`;

const buttonVariants = {
  idle: {
    scale: 1,
  },
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.3,
      ease: "easeInOut"
    }
  },
  tap: {
    scale: 0.95
  },
  submitting: {
    scale: 0.98,
    opacity: 0.8
  }
};

const loadingSpinnerVariants = {
  submitting: {
    opacity: 1,
    rotate: 360,
    transition: {
      rotate: {
        duration: 1,
        ease: "linear",
        repeat: Infinity
      }
    }
  },
  idle: { opacity: 0 }
};

const MapContainer = styled(motion.div)`
  width: 100%;
  height: 400px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin-top: 4rem;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const SocialLink = styled.a`
  color: var(--primary-color);
  font-size: 1.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: var(--accent-color);
  }
`;

const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID || '';
const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID || '';
const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY || '';
const RECIPIENT_EMAIL = 'info@nellosafari.com';

if (!EMAILJS_SERVICE_ID || !EMAILJS_TEMPLATE_ID || !EMAILJS_PUBLIC_KEY) {
  console.error('EmailJS configuration is missing. Please check your environment variables.');
}

emailjs.init(EMAILJS_PUBLIC_KEY);

const ContattiSection = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    oggetto: '',
    messaggio: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.nome || !formData.email || !formData.oggetto || !formData.messaggio) {
      alert('Per favore, compila tutti i campi del form.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      alert('Per favore, inserisci un indirizzo email valido.');
      return;
    }

    setIsSubmitting(true);
    setSubmitStatus('idle');

    try {
      const templateParams = {
        to_email: RECIPIENT_EMAIL,
        from_name: formData.nome,
        to_name: 'Nello Safari',
        reply_to: formData.email,
        email: formData.email,
        subject: formData.oggetto,
        message_html: `
🧑‍💼 Dettagli Contatto:
Nome: ${formData.nome}
Email: ${formData.email}

📝 Oggetto: ${formData.oggetto}

💬 Messaggio:
${formData.messaggio}

--
Questo messaggio è stato inviato dal form di contatto su NelloSafari.com
        `.trim(),
        message: formData.messaggio
      };

      const result = await emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        templateParams
      );

      if (result.status === 200) {
        setSubmitStatus('success');
        setFormData({
          nome: '',
          email: '',
          oggetto: '',
          messaggio: ''
        });
        alert('Grazie! Il tuo messaggio è stato inviato con successo. Ti risponderemo presto a ' + formData.email);
      } else {
        throw new Error('Errore nell\'invio del messaggio');
      }
    } catch (error) {
      console.error('Errore durante l\'invio dell\'email:', error);
      setSubmitStatus('error');
      alert('Si è verificato un errore durante l\'invio del messaggio. Per favore, riprova più tardi o contattaci via WhatsApp al +254 746473595');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ContattiContainer>
      <ContattiContent>
        <SectionTitle
          initial={{ opacity: 0, y: 30 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8 }}
        >
          Contattaci
        </SectionTitle>
        <SectionSubtitle
          initial={{ opacity: 0, y: 30 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Siamo qui per rispondere a tutte le tue domande
        </SectionSubtitle>

        <ContactGrid ref={ref}>
          <ContactInfo>
            <InfoCard
              initial={{ opacity: 0, x: -30 }}
              animate={inView ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 0.5 }}
            >
              <IconWrapper>
                <FaPhone />
              </IconWrapper>
              <InfoContent>
                <InfoTitle>Telefono</InfoTitle>
                <InfoText>
                  <ContactLink href="tel:+254 746473595">+254 746473595</ContactLink>
                </InfoText>
              </InfoContent>
            </InfoCard>

            <InfoCard
              initial={{ opacity: 0, x: -30 }}
              animate={inView ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <IconWrapper>
                <FaEnvelope />
              </IconWrapper>
              <InfoContent>
                <InfoTitle>Email</InfoTitle>
                <InfoText>
                  <ContactLink href="mailto:info@nellosafari.com">info@nellosafari.com</ContactLink>
                </InfoText>
              </InfoContent>
            </InfoCard>

            <InfoCard
              initial={{ opacity: 0, x: -30 }}
              animate={inView ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <IconWrapper>
                <FaMapMarkerAlt />
              </IconWrapper>
              <InfoContent>
                <InfoTitle>Indirizzo</InfoTitle>
                <InfoText>
                  Malindi<br />
                  P.O Box 381, Kenya
                </InfoText>
                <SocialLinks>
                  <SocialLink href="https://wa.me/254746473595" target="_blank">
                    <FaWhatsapp />
                  </SocialLink>
                  <SocialLink href="https://facebook.com/nellosafari" target="_blank">
                    <FaFacebook />
                  </SocialLink>
                  <SocialLink href="https://instagram.com/nellosafari" target="_blank">
                    <FaInstagram />
                  </SocialLink>
                </SocialLinks>
              </InfoContent>
            </InfoCard>
          </ContactInfo>

          <FormContainer
            initial={{ opacity: 0, x: 30 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8 }}
          >
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Label>Nome e Cognome</Label>
                <Input
                  type="text"
                  name="nome"
                  value={formData.nome}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>Oggetto</Label>
                <Input
                  type="text"
                  name="oggetto"
                  value={formData.oggetto}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>Messaggio</Label>
                <TextArea
                  name="messaggio"
                  value={formData.messaggio}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <SubmitButtonContainer>
                <SubmitButton
                  type="submit"
                  disabled={isSubmitting}
                  variants={buttonVariants}
                  initial="idle"
                  whileHover="hover"
                  whileTap="tap"
                  animate={isSubmitting ? "submitting" : "idle"}
                >
                  {isSubmitting ? (
                    <LoadingSpinner
                      variants={loadingSpinnerVariants}
                      initial="idle"
                      animate="submitting"
                    />
                  ) : (
                    'Invia Messaggio'
                  )}
                </SubmitButton>
              </SubmitButtonContainer>
            </form>
          </FormContainer>
        </ContactGrid>

        <MapContainer
          initial={{ opacity: 0, y: 30 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8 }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63735.73107054985!2d40.048598280055984!3d-3.2292098169185923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x18158fa8aba15693%3A0xcbebf1008265d79d!2sMalindi%2C%20Kenya!5e0!3m2!1sit!2sit!4v1731884899876!5m2!1sit!2sit"
            title="NelloSafari Location"
            loading="lazy"
          />
        </MapContainer>
      </ContattiContent>
    </ContattiContainer>
  );
};

export default ContattiSection;
