import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaArrowLeft } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';

const ServicesContainer = styled.section`
  padding: 100px 0;
  background: var(--background-color);
  max-width: 1400px;
  margin: 0 auto;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  padding: 0 2rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const CardContainer = styled.div`
  perspective: 2000px;
  height: 600px;
  width: 100%;
  transform-style: preserve-3d;
  margin: 0 auto;
  max-width: 550px;

  @media (max-width: 768px) {
    height: 550px;
  }
`;

const Card = styled(motion.div)<{ $isFlipped?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  transform: ${props => props.$isFlipped ? 'rotateY(180deg)' : 'rotateY(0)'};
  cursor: pointer;
  transform-origin: center;
  display: flex;
  flex-direction: column;
`;

const CardSide = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.1),
    0 3px 10px rgba(0, 0, 0, 0.07),
    inset 0 0 0 1px rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.7);
  transform-style: preserve-3d;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:hover {
    background: rgba(255, 255, 255, 0.92);
    box-shadow: 
      0 20px 40px rgba(0, 0, 0, 0.12),
      0 5px 15px rgba(0, 0, 0, 0.08),
      inset 0 0 0 1px rgba(255, 255, 255, 1);
    transform: translateY(-10px);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(
      105deg,
      transparent 35%,
      rgba(255, 255, 255, 0.18) 45%,
      rgba(255, 255, 255, 0.25) 50%,
      rgba(255, 255, 255, 0.18) 55%,
      transparent 65%
    );
    transform: translateX(-100%);
    transition: transform 1.2s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 2;
  }

  &:hover::before {
    transform: translateX(100%);
  }
`;

const CardFront = styled(CardSide)`
  transform: rotateY(0);
`;

const CardBack = styled(CardSide)`
  transform: rotateY(180deg);
  padding: 2rem;
  background: rgba(255, 255, 255, 0.95);
  overflow-y: auto;

  &:hover {
    transform: rotateY(180deg) translateY(-5px);
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #C19B76;
    border-radius: 3px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 50%;
  position: relative;
  overflow: hidden;
  border-radius: 25px 25px 0 0;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 75%;
    background: linear-gradient(to top, 
      rgba(0, 0, 0, 0.85) 0%,
      rgba(0, 0, 0, 0.6) 35%,
      rgba(0, 0, 0, 0.2) 60%,
      transparent 100%
    );
    pointer-events: none;
    transition: all 0.5s ease;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1.2s cubic-bezier(0.23, 1, 0.32, 1);
    transform-origin: center center;
    filter: saturate(1.1) contrast(1.1);
  }

  ${CardFront}:hover & img {
    transform: scale(1.12) translateY(-2%);
  }

  ${CardFront}:hover &::after {
    height: 85%;
    background: linear-gradient(to top, 
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.7) 35%,
      rgba(0, 0, 0, 0.3) 60%,
      transparent 100%
    );
  }
`;

const CardContent = styled.div`
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.3rem;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.97),
    rgba(255, 255, 255, 0.95)
  );
  position: relative;
  z-index: 1;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
`;

const ServiceTitle = styled.h3`
  font-size: 1.8rem;
  color: #1a1a1a;
  margin: 0;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #C19B76 0%, #8B6343 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 0.5rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background: #C19B76;
    transition: width 0.3s ease;
  }

  ${CardFront}:hover &::after {
    width: 100px;
  }

  ${CardBack} & {
    margin: 1.5rem 0;
    text-align: center;
  }
`;

const ServiceDescription = styled.p`
  color: #333;
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 0;
  font-weight: 400;

  ${CardFront} & {
    color: rgba(51, 51, 51, 0.95);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  ${CardBack} & {
    margin-bottom: 1.5rem;
  }
`;

const DetailsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const DetailItem = styled.li`
  color: #333;
  line-height: 1.6;
  padding: 0.8rem 1rem;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  transition: all 0.3s ease;
  position: relative;
  padding-left: 1.5rem;

  &::before {
    content: "•";
    color: #C19B76;
    position: absolute;
    left: 0.5rem;
    font-weight: bold;
    transition: all 0.3s ease;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.9);
    transform: translateX(5px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

    &::before {
      color: #8B6343;
    }
  }
`;

const BackButton = styled.button`
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  svg {
    color: #1a1a1a;
    font-size: 1.2rem;
  }
`;

const SectionTitle = styled(motion.h2)`
  text-align: center;
  font-size: 3rem;
  margin-bottom: 4rem;
  color: var(--text-color);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: var(--primary-color);
  }
`;

const services = [
  {
    title: 'Alloggi esclusivi',
    description: 'Soluzioni di alloggio esclusive e personalizzate, per ogni esigenza e preferenza.',
    image: '/images/services/alloggi.jpg',
    details: [
      'Ville private con servizio completo',
      'Suite in Hotel/Resort',
      'Servizio di pulizia giornaliero',
      'Connessione Wi-Fi',
      'Servizio concierge',
      'Piscina privata',
      'Opzione Chef privato (Cibo & Bevande incluse)',
      'Sicurezza H24'
    ]
  },
  {
    title: 'Autista Privato',
    description: 'Servizio autista professionale disponibile 24/7, con veicoli da 4 a 8 posti.',
    image: '/images/services/autista.jpg',
    details: [
      'Autisti professionisti multilingue',
      'Veicoli climatizzati',
      'Servizio disponibile 24 ore su 24',
      'Pick-up e drop-off aeroportuali',
      'Tour personalizzati della città',
      'Veicoli 4x4 per safari',
      'Massima discrezione e professionalità',
      'Prenotazione anticipata o servizio On-demand'
    ]
  },
  {
    title: 'Safari Privati',
    description: 'Avventure indimenticabili alla scoperta della fauna selvatica (giorno/notte).',
    image: '/images/services/safari.jpg',
    details: [
      'Safari privati guidati',
      'Guide esperte certificate',
      'Veicoli 4x4 completamente attrezzati',
      'Safari notturni',
      'Colazione/Pranzo/Cena (Bevande escluse)',
      'Avvistamento dei Big Five',
      'Alloggi in lodge/tende nella savana',
      'Opzioni Safari in mongolfiera, a cavallo e in mountain bike'
    ]
  },
  {
    title: 'Escursioni',
    description: 'Tour guidati personalizzati per esplorare le meraviglie naturali africane.',
    image: '/images/services/escursioni.jpg',
    details: [
      'Trekking nelle riserve naturali',
      'Tour in barca fino al tramonto',
      'Escursioni in mongolfiera',
      'Visite alle cascate e ai laghi',
      'Birdwatching con esperti',
      'Tour guidati',
      'Escursioni in mountain bike',
      'Snorkeling e immersioni'
    ]
  },
  {
    title: 'Esperienze Culturali',
    description: 'Immergiti nella cultura locale con tour privati, workshop e attività culturali con le tribù locali.',
    image: '/images/services/cultura.jpg',
    details: [
      'Visite ai villaggi tradizionali',
      'Workshop di artigianato locale',
      'Lezioni di cucina tradizionale (Cooking class)',
      'Cerimonie tribali autentiche',
      'Danze e musica tradizionale',
      'Mercati locali guidati',
      'Incontri con gli anziani del villaggio',
      'Attività di volontariato comunitario'
    ]
  },
  {
    title: 'Chef Personale',
    description: 'Chef professionisti per creare esperienze culinarie uniche e indimenticabili. (Colazione/Pranzo/Cena).',
    image: '/images/services/chef.jpg',
    details: [
      'Menu personalizzati secondo preferenze',
      'Cucina fusion afro-italiana',
      'Cene romantiche sulla spiaggia',
      'Cooking class interattive',
      'Selezione di vini e bevande locali',
      'Barbecue africani tradizionali',
      'Opzioni vegetariane e vegane',
      'Servizio catering per eventi speciali'
    ]
  }
];

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: (i: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.1,
      duration: 0.5,
      ease: [0.4, 0, 0.2, 1]
    }
  })
};

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const ServiziSection: React.FC = () => {
  const [flippedCard, setFlippedCard] = useState<number | null>(null);
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  const handleCardClick = (index: number) => {
    setFlippedCard(flippedCard === index ? null : index);
  };

  const handleBackClick = (e: React.MouseEvent, index: number) => {
    e.stopPropagation();
    setFlippedCard(null);
  };

  return (
    <ServicesContainer>
      <SectionTitle
        initial={{ opacity: 0, y: 30 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        I Nostri Servizi
      </SectionTitle>
      <ServicesGrid
        ref={ref}
        as={motion.div}
        variants={containerVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <AnimatePresence>
          {services.map((service, index) => (
            <motion.div
              key={index}
              variants={cardVariants}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              custom={index}
              layout
            >
              <CardContainer>
                <Card
                  $isFlipped={flippedCard === index}
                  onClick={() => handleCardClick(index)}
                >
                  <CardFront>
                    <ImageContainer>
                      <img 
                        src={service.image} 
                        alt={service.title}
                        onError={(e) => {
                          const target = e.target as HTMLImageElement;
                          target.src = '/images/services/placeholder.jpg';
                        }}
                      />
                    </ImageContainer>
                    <CardContent>
                      <ServiceTitle>{service.title}</ServiceTitle>
                      <ServiceDescription>{service.description}</ServiceDescription>
                    </CardContent>
                  </CardFront>
                  <CardBack>
                    <BackButton onClick={(e) => handleBackClick(e, index)}>
                      <FaArrowLeft />
                    </BackButton>
                    <ServiceTitle>{service.title}</ServiceTitle>
                    <DetailsList>
                      {service.details.map((detail, idx) => (
                        <DetailItem key={idx}>{detail}</DetailItem>
                      ))}
                    </DetailsList>
                  </CardBack>
                </Card>
              </CardContainer>
            </motion.div>
          ))}
        </AnimatePresence>
      </ServicesGrid>
    </ServicesContainer>
  );
};

export default ServiziSection;
