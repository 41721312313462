import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaHeart, FaCompass, FaUsers } from 'react-icons/fa';

const AboutContainer = styled.section`
  padding: 100px 0;
  background: var(--background-color);
`;

const AboutContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const StorySection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin-bottom: 6rem;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const TextContent = styled(motion.div)`
  h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: var(--primary-color);
  }

  p {
    font-size: 1.1rem;
    line-height: 1.8;
    color: var(--text-color);
    margin-bottom: 1.5rem;
  }
`;

const ImageWrapper = styled(motion.div)`
  position: relative;
  height: 500px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  will-change: transform;
  backface-visibility: hidden;
  transform: translateZ(0);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translateZ(0);
    will-change: transform;
    loading: "lazy";
  }

  @media (max-width: 768px) {
    height: 300px;
  }
`;

const ValuesSection = styled.div`
  margin-top: 4rem;
  text-align: center;

  h3 {
    font-size: 2rem;
    margin-bottom: 3rem;
    color: var(--primary-color);
  }
`;

const ValuesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ValueCard = styled(motion.div)`
  padding: 2rem;
  background: white;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  svg {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--primary-color);
  }

  p {
    color: var(--text-color);
    line-height: 1.6;
  }
`;

const AboutSection = () => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
    rootMargin: '50px'
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
        staggerChildren: 0.2
      }
    }
  };

  return (
    <AboutContainer>
      <AboutContent>
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <StorySection>
            <TextContent>
              <h2>La Nostra Storia</h2>
              <p>
              NelloSafari è il risultato di un profondo amore per il Kenya, un legame che nasce dalla nostra appartenenza alla tribù Giriama, una delle comunità più antiche e autentiche della costa kenyana. Questo legame ci permette di condividere con te la vera essenza di questa terra straordinaria, attraverso esperienze uniche e irripetibili. Non siamo solo guide: siamo narratori di storie, custodi di tradizioni, ambasciatori di una cultura millenaria che desideriamo farti vivere in modo autentico.

Ogni viaggio che organizziamo è pensato per essere molto più di una vacanza. È un’esperienza immersiva, creata su misura per chi desidera entrare in contatto con la natura incontaminata, scoprire le radici culturali di un popolo ospitale e vivere momenti di pura magia. Con NelloSafari, ogni dettaglio conta. Dalle escursioni avventurose ai safari privati, dai tramonti sulle dune alle cene sotto le stelle, ogni esperienza è progettata per essere indimenticabile.

La nostra professionalità si riflette nella cura con cui organizziamo ogni singolo aspetto del tuo viaggio. Dall’accoglienza con shuttle privati, agli alloggi esclusivi con chef personale, fino ai safari e alle esperienze culturali, tutto è pensato per offrirti comfort, sicurezza e autenticità. Vogliamo che il tuo viaggio sia unico, fatto su misura per te, e che rispecchi il tuo desiderio di esplorare, rilassarti e scoprire il vero Kenya.
              </p>
              <p>
              Crediamo in un turismo sostenibile e responsabile, che rispetti l’ambiente, valorizzi le comunità locali e protegga le tradizioni. Questo è il nostro impegno, il nostro modo di ringraziare questa terra che ci regala tanta bellezza e che desideriamo condividere con te.

Che tu stia esplorando la savana, incontrando le tribù locali o rilassandoti sulle acque cristalline, con NelloSafari scoprirai il Kenya in tutta la sua autenticità. Sarà un viaggio che resterà con te per sempre, fatto di emozioni, connessioni e momenti che ti cambieranno per sempre.
              </p>
            </TextContent>
            <ImageWrapper
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 0 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            >
              <img 
                src="/images/about-story.jpg" 
                alt="La nostra storia nel safari"
                loading="lazy"
                decoding="async"
              />
            </ImageWrapper>
          </StorySection>

          <ValuesSection>
            <h3>I Nostri Valori</h3>
            <ValuesGrid>
              <ValueCard
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { 
                    opacity: 1, 
                    y: 0,
                    transition: { duration: 0.3 }
                  }
                }}
                whileHover={{ scale: 1.02 }}
              >
                <FaHeart />
                <h4>Passione</h4>
                <p>Mettiamo il cuore in ogni viaggio che organizziamo, curando ogni dettaglio con dedizione.</p>
              </ValueCard>
              <ValueCard
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { 
                    opacity: 1, 
                    y: 0,
                    transition: { duration: 0.3 }
                  }
                }}
                whileHover={{ scale: 1.02 }}
              >
                <FaCompass />
                <h4>Esperienza</h4>
                <p>Anni di esperienza sul campo ci permettono di offrire i migliori itinerari e servizi.</p>
              </ValueCard>
              <ValueCard
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { 
                    opacity: 1, 
                    y: 0,
                    transition: { duration: 0.3 }
                  }
                }}
                whileHover={{ scale: 1.02 }}
              >
                <FaUsers />
                <h4>Personalizzazione</h4>
                <p>Ogni safari è su misura, adattato alle esigenze e ai desideri dei nostri clienti.</p>
              </ValueCard>
            </ValuesGrid>
          </ValuesSection>
        </motion.div>
      </AboutContent>
    </AboutContainer>
  );
};

export default AboutSection;
