import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBars, FaTimes, FaWhatsapp } from 'react-icons/fa';
import logoNavbar from '../../assets/logo-navbar.png';

const Nav = styled(motion.nav)<{ $scrolled: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: ${props => props.$scrolled 
    ? 'rgba(15, 15, 15, 0.85)' 
    : 'linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)'};
  padding: ${props => props.$scrolled ? '1rem 0' : '1.5rem 0'};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: ${props => props.$scrolled ? 'blur(10px) saturate(180%)' : 'blur(5px)'};
  border-bottom: ${props => props.$scrolled ? '1px solid rgba(255,255,255,0.1)' : 'none'};
  box-shadow: ${props => props.$scrolled 
    ? '0 10px 30px -10px rgba(0,0,0,0.3)' 
    : 'none'};
  will-change: transform, opacity, backdrop-filter;
  transform: translateZ(0);

  @media (max-width: 768px) {
    padding: ${props => props.$scrolled ? '0.7rem 0' : '0.8rem 0'};
    height: ${props => props.$scrolled ? 'auto' : '110px'};
    display: flex;
    align-items: center;
  }
`;

const NavContainer = styled.div<{ $atTop: boolean }>`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: ${props => props.$atTop ? 'center' : 'space-between'};
  align-items: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  height: 100%;

  @media (max-width: 768px) {
    padding: 0 1.2rem;
    justify-content: ${props => props.$atTop ? 'center' : 'space-between'};
    width: 100%;
    max-width: 100%;
    height: ${props => props.$atTop ? '100%' : 'auto'};
  }
`;

const Logo = styled(Link)<{ $atTop: boolean }>`
  text-decoration: none;
  z-index: 100;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  position: ${props => props.$atTop ? 'absolute' : 'relative'};
  left: ${props => props.$atTop ? '50%' : '0'};
  transform: ${props => props.$atTop ? 'translateX(-50%)' : 'translateX(0) scale(0.75)'};
  transform-origin: left center;
  padding: ${props => props.$atTop ? '0.5rem 0' : '0'};
  
  img {
    height: ${props => props.$atTop ? '120px' : '90px'};
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    filter: drop-shadow(0 0 10px rgba(0,0,0,0.2));
  }

  &:hover {
    transform: ${props => props.$atTop ? 'translateX(-50%) scale(1.02)' : 'translateX(0) scale(0.8)'};
  }

  @media (max-width: 768px) {
    position: relative;
    left: auto;
    transform: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.$atTop ? '100%' : 'auto'};
    max-width: ${props => props.$atTop ? '340px' : 'none'};
    margin: ${props => props.$atTop ? '0 auto' : '0'};
    
    img {
      height: ${props => props.$atTop ? '90px' : '55px'};
      width: auto;
      object-fit: contain;
      transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
    
    &:hover {
      transform: ${props => props.$atTop ? 'scale(1.02)' : 'scale(1.02)'};
    }
  }
`;

const NavLinks = styled.div<{ $isOpen: boolean; $atTop: boolean }>`
  display: flex;
  align-items: center;
  gap: 2rem;
  opacity: ${props => props.$atTop ? '0' : '1'};
  visibility: ${props => props.$atTop ? 'hidden' : 'visible'};
  transform: ${props => props.$atTop ? 'translateY(10px)' : 'translateY(0)'};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  margin-left: auto;
  height: 100%;
  justify-content: center;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    right: ${props => props.$isOpen ? '0' : '-100%'};
    height: 100vh;
    width: 85%;
    max-width: 360px;
    background: rgba(15, 15, 15, 0.98);
    backdrop-filter: blur(15px) saturate(180%);
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 6rem;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: ${props => props.$isOpen ? '-10px 0 30px rgba(0,0,0,0.3)' : 'none'};
    border-left: 1px solid rgba(255,255,255,0.1);
    opacity: 1;
    visibility: visible;
    transform: none;
    margin-left: 0;
    gap: 0;
    pointer-events: ${props => props.$atTop ? 'none' : 'auto'};
  }
`;

const NavLink = styled(Link)<{ $active: boolean }>`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: ${props => props.$active ? '100%' : '0'};
    height: 2px;
    background: #C19B76;
    transform: translateX(-50%);
    transition: width 0.3s ease;
  }

  &:hover {
    color: #C19B76;
    &::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem 2rem;
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 1px;
    border-bottom: 1px solid rgba(255,255,255,0.05);
    
    &:first-child {
      border-top: 1px solid rgba(255,255,255,0.05);
    }

    &::after {
      display: none;
    }

    &:hover, &:active {
      background: rgba(193,155,118,0.1);
    }

    ${props => props.$active && `
      background: rgba(193,155,118,0.15);
      color: #C19B76;
    `}
  }
`;

const WhatsAppButton = styled(motion.a)<{ $atTop: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: #25D366;
  color: white;
  text-decoration: none;
  padding: 0.6rem 1.2rem;
  border-radius: 50px;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.3s ease;
  margin-left: 1rem;
  white-space: nowrap;
  box-shadow: 0 4px 15px rgba(37, 211, 102, 0.2);

  svg {
    font-size: 1.1rem;
  }

  &:hover {
    background: #22c35e;
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(37, 211, 102, 0.3);
  }

  @media (max-width: 768px) {
    margin: 1.5rem 2rem;
    padding: 0.8rem 1.5rem;
    justify-content: center;
    width: calc(100% - 4rem);
    font-size: 1rem;
    background: #25D366;
    box-shadow: 0 4px 15px rgba(37, 211, 102, 0.2);

    &:hover {
      background: #22c35e;
      box-shadow: 0 6px 20px rgba(37, 211, 102, 0.3);
      transform: translateY(-2px);
    }
  }
`;

const NavLinkWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
`;

const MenuButton = styled.button<{ $atTop: boolean }>`
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 100;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  backdrop-filter: blur(10px);
  background: rgba(255,255,255,0.1);
  opacity: ${props => props.$atTop ? '0' : '1'};
  visibility: ${props => props.$atTop ? 'hidden' : 'visible'};

  &:hover {
    color: #C19B76;
    transform: rotate(180deg);
    background: rgba(255,255,255,0.15);
  }

  @media (max-width: 768px) {
    display: ${props => props.$atTop ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    font-size: 1.3rem;
    background: ${props => props.$atTop ? 'rgba(255,255,255,0.1)' : 'rgba(15,15,15,0.8)'};
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255,255,255,0.1);
    opacity: ${props => props.$atTop ? '0' : '1'};
    visibility: ${props => props.$atTop ? 'hidden' : 'visible'};
    margin-left: 1rem;
    transform: translateX(${props => props.$atTop ? '20px' : '0'});
    
    &:hover {
      transform: ${props => props.$atTop ? 'translateX(20px)' : 'none'};
      background: rgba(193,155,118,0.2);
    }

    &:active {
      transform: ${props => props.$atTop ? 'translateX(20px)' : 'scale(0.95)'};
    }
  }
`;

const MobileNavOverlay = styled(motion.div)<{ $isOpen: boolean }>`
  display: none;
  
  @media (max-width: 768px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    opacity: ${props => props.$isOpen ? 1 : 0};
    visibility: ${props => props.$isOpen ? 'visible' : 'hidden'};
    transition: all 0.3s ease;
    z-index: 90;
  }
`;

const navVariants = {
  hidden: { 
    opacity: 0,
    y: -20,
  },
  visible: { 
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: [0.25, 0.1, 0.25, 1],
    },
  },
};

const linkVariants = {
  hidden: { 
    opacity: 0,
    x: -10,
  },
  visible: { 
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.2,
      ease: [0.25, 0.1, 0.25, 1],
    },
  },
};

const overlayVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: "easeIn",
    },
  },
};

const Navbar: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  const location = useLocation();

  useEffect(() => {
    let ticking = false;
    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const isScrolled = window.scrollY > 50;
          const isAtTop = window.scrollY < 10;
          setIsScrolled(isScrolled);
          setIsAtTop(isAtTop);
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  return (
    <Nav
      $scrolled={isScrolled}
      variants={navVariants}
      initial="hidden"
      animate="visible"
    >
      <NavContainer $atTop={isAtTop}>
        <Logo to="/" $atTop={isAtTop}>
          <img src={logoNavbar} alt="NelloSafari Logo" />
        </Logo>

        <MenuButton onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} $atTop={isAtTop}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </MenuButton>

        <AnimatePresence>
          {isMobileMenuOpen && (
            <MobileNavOverlay
              $isOpen={isMobileMenuOpen}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setIsMobileMenuOpen(false)}
            />
          )}
        </AnimatePresence>

        <NavLinks $isOpen={isMobileMenuOpen} $atTop={isAtTop}>
          <NavLinkWrapper variants={linkVariants}>
            <NavLink 
              to="/" 
              $active={location.pathname === '/'} 
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Home
            </NavLink>
          </NavLinkWrapper>

          <NavLinkWrapper variants={linkVariants}>
            <NavLink 
              to="/about" 
              $active={location.pathname === '/about'}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Chi Siamo
            </NavLink>
          </NavLinkWrapper>

          <NavLinkWrapper variants={linkVariants}>
            <NavLink 
              to="/servizi" 
              $active={location.pathname === '/servizi'}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Servizi
            </NavLink>
          </NavLinkWrapper>

          <NavLinkWrapper variants={linkVariants}>
            <NavLink 
              to="/mete" 
              $active={location.pathname === '/mete'}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Destinazioni
            </NavLink>
          </NavLinkWrapper>

          <NavLinkWrapper variants={linkVariants}>
            <NavLink 
              to="/gallery" 
              $active={location.pathname === '/gallery'}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Gallery
            </NavLink>
          </NavLinkWrapper>

          <NavLinkWrapper variants={linkVariants}>
            <NavLink 
              to="/preventivo" 
              $active={location.pathname === '/preventivo'}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Preventivo
            </NavLink>
          </NavLinkWrapper>

          <NavLinkWrapper variants={linkVariants}>
            <NavLink 
              to="/contatti" 
              $active={location.pathname === '/contatti'}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Contatti
            </NavLink>
          </NavLinkWrapper>

          <WhatsAppButton
            href="https://wa.me/254746473595"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            $atTop={isAtTop}
          >
            <FaWhatsapp /> Contattaci su WhatsApp
          </WhatsAppButton>
        </NavLinks>
      </NavContainer>
    </Nav>
  );
};

export default Navbar;
